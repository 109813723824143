import { INGSnackbarProps } from "../../library/NGFieldExtensions";
import { computed, useSignal, useSignalEffect } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import "./NGSnackbar.css";
import NGButton from "../NGButton/NGButton";
import { getTestId } from "../../library/utils";
import { isNil, toNumber } from "lodash-es";
import { useSnackbar } from "notistack";

export default function NGSnackbar({ config, context }: INGSnackbarProps) {
  const local = setupLocalState(
    config,
    {
      Open: useSignal(config.Open ?? false),
      Message: useSignal(config.Message ?? ""),
      Severity: useSignal(config.Severity ?? "error"),
      AutoHideDuration: useSignal(config.AutoHideDuration ?? 5000),
    },
    context
  );

  const handlers = setupHandlers(config, context);
  const { enqueueSnackbar } = useSnackbar();

  const autohide = computed(() => {
    if (config.Autohide === false) return null;
    if (!isNil(local.AutoHideDuration.value)) return toNumber(local.AutoHideDuration.value);

    return config.AutoHideDuration ?? 5000;
  });

  useSignalEffect(() => {
    if (local.Message.value) {
      enqueueSnackbar(
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            justifyItems: "space-between",
            width: "100%",
          }}
        >
          <div>
            {local.Message.value}
            {config.ShowAnimation && <span className="animated-ellipsis"></span>}
          </div>
          {config.Button && <NGButton config={config.Button} context={context} />}
        </div>,
        {
          variant: local.Severity.value,
          autoHideDuration: autohide.value,
          SnackbarProps: { ["data-testid"]: getTestId(config) },
          onClose: (e, reason) => {
            if (reason == "clickaway") return;

            if (handlers["onClose"]) {
              handlers["onClose"](e, e);
            }
          },
        }
      );
    }
  });

  return <></>;
}
