import React from "react";
import { ButtonProps } from "@mui/material/Button";
import { TooltipProps } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { SaveAlt } from "@mui/icons-material";
import MenuList from "@mui/material/MenuList";
import { Button, Menu } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { GridApi } from "ag-grid-charts-enterprise";
import { GridCsvExportProps, GridPrintExportProps, INGListExportButtonProps } from "../../library/NGFieldExtensions";
import { GetCustomLabelForListFromSiteSettings, GetSettingFromSite, setupLocalState } from "../../library/dataService";
import { useSignal } from "@preact/signals-react";
import { getClassName, getClassNameFromString, getsxObject, getTestId } from "../../library/utils";
import { getGridContext } from "../../library/metadataUtils";
import { ListExportButton } from "../../../resolvers-types";
import { getExportDataDefaults } from "./NGListUtils";

export interface GridExportDisplayOptions {
  /**
   * If `true`, this export option will be removed from the GridToolbarExport menu.
   * @default false
   */
  disableToolbarButton?: boolean;
}

export interface GridExportMenuItemProps<Options> {
  hideMenu?: () => void;
  options?: Options & GridExportDisplayOptions;
  apiRef: React.RefObject<AgGridReact<any>>;
}

export type GridCsvExportMenuItemProps = GridExportMenuItemProps<GridCsvExportProps>;

export type GridPrintExportMenuItemProps = GridExportMenuItemProps<GridPrintExportProps>;

export interface GridToolbarExportProps {
  csvOptions?: GridCsvExportProps & GridExportDisplayOptions;
  printOptions?: GridPrintExportProps & GridExportDisplayOptions;
  /**
   * The props used for each slot inside.
   * @default {}
   */
  slotProps?: { button?: Partial<ButtonProps>; tooltip?: Partial<TooltipProps> };
  apiRef: React.RefObject<AgGridReact<any>>;
  [key: string]: any;
}

export function GridCsvExportMenuItem(props: GridCsvExportMenuItemProps) {
  const { hideMenu, options, apiRef, ...other } = props;

  return (
    <MenuItem
      onClick={() => {
        apiRef.current?.api.exportDataAsCsv({
          ...getExportDataDefaults(apiRef.current?.api),
          ...options,
        });
        hideMenu?.();
      }}
      {...other}
    >
      Download as CSV
    </MenuItem>
  );
}

export function GridPrintExportMenuItem(props: GridPrintExportMenuItemProps) {
  const { hideMenu, apiRef, ...other } = props;

  function onBtPrint() {
    setPrinterFriendly(apiRef.current?.api);

    setTimeout(() => {
      print();
      setNormal(apiRef.current?.api);
    }, 500);
  }

  function setPrinterFriendly(api?: GridApi) {
    api?.setGridOption("domLayout", "print");
  }

  function setNormal(api?: GridApi) {
    api?.setGridOption("domLayout", undefined);
  }

  return (
    <MenuItem
      onClick={() => {
        onBtPrint();
        hideMenu?.();
      }}
      {...other}
    >
      Print
    </MenuItem>
  );
}

export function GridExcelExportMenuItem(props: GridPrintExportMenuItemProps) {
  const { hideMenu, options, apiRef, ...other } = props;

  return (
    <MenuItem
      onClick={() => {
        apiRef.current?.api.exportDataAsExcel({
          ...getExportDataDefaults(apiRef.current?.api),
          ...options,
        });
        hideMenu?.();
      }}
      {...other}
    >
      Download as Excel
    </MenuItem>
  );
}

interface GridToolbarExportContainerProps {
  slotProps?: { button?: Partial<ButtonProps>; tooltip?: Partial<TooltipProps> };
  children?: React.ReactNode;
  config: ListExportButton;
}

function GridToolbarExportContainer(props: GridToolbarExportContainerProps) {
  const { children, slotProps = {}, config } = props;
  const buttonProps = slotProps.button || {};
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prevOpen) => !prevOpen);
    buttonProps.onClick?.(event);
  };

  const handleMenuClose = () => setOpen(false);

  if (children == null) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        sx={getsxObject(config.Style, {
          textTransform: "none",
        })}
        className={getClassNameFromString(config.Classes)}
        data-testid={props["data-testid"]}
        data-type={props["data-type"]}
        ref={buttonRef}
        size="small"
        startIcon={GetSettingFromSite("HideListExportButton") !== true && <SaveAlt />}
        aria-expanded={open}
        aria-haspopup="menu"
        aria-controls={open ? "export1" : undefined}
        id="export1"
        {...buttonProps}
        onClick={handleMenuOpen}
      >
        {GetCustomLabelForListFromSiteSettings("Export")}
      </Button>
      <Menu open={open} anchorEl={buttonRef.current} onClose={handleMenuClose}>
        <MenuList id="export1" autoFocusItem={open}>
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return child;
            }
            return React.cloneElement<any>(child, { hideMenu: handleMenuClose });
          })}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
}

function NGListExportButton({ config, context }: INGListExportButtonProps) {
  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
    },
    context
  );

  const { GridRef } = getGridContext(context);

  return local.Visible.value ? (
    <GridToolbarExportContainer data-testid={getTestId(config)} data-type={config.__typename} config={config}>
      {config.EnableCSV && <GridCsvExportMenuItem apiRef={GridRef} />}
      {config.EnableExcel && <GridExcelExportMenuItem apiRef={GridRef} />}
      {config.EnablePrint && <GridPrintExportMenuItem apiRef={GridRef} />}
    </GridToolbarExportContainer>
  ) : null;
}

export { NGListExportButton, GridToolbarExportContainer };
