import { INGInputFieldProps } from "../../library/NGFieldExtensions";
import { InputAdornment, TextField, TextareaAutosize } from "@mui/material";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import { Result } from "../../models/result";
import { KeyboardEvent, useContext, useRef } from "react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, getsxObject, getClassName, ignoreCustomHandlers, getCustomLabel } from "../../library/utils";
import { debounce, isNil, toNumber } from "lodash-es";
import { GridStackContext } from "../../layouts/NGGridStackLayout";
import NGIcon from "../NGIcon/NGIcon";
import { NGContextAutocomplete } from "../NGContextAutocomplete/NGContextAutocomplete";

export default function NGInputField({ config, context }: INGInputFieldProps) {
  // const valid = useSignal({ success: true } as Result<any>);

  const local = setupLocalState(
    config,
    {
      DefaultValue: useSignal(config.DefaultValue ?? ""),
      Value: useSignal(config.DefaultValue ?? ""),
      Label: useSignal(config.Label ?? ""),
      Visible: useSignal(config.Visible ?? true),
      Multiline: useSignal(config.Multiline ?? false),
      MaxRows: useSignal(config.MaxRows ?? null),
      MinRows: useSignal(config.MinRows ?? null),
      Rows: useSignal(config.Rows ?? null),
      Disabled: useSignal(config.Disabled ?? false),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
      FocusOnDisplay: useSignal(config.FocusOnDisplay ?? false),
      Debounce: useSignal(config.Debounce ?? 1000),
      Valid: useSignal(config.Valid ?? { success: true, reasons: [] }),
    },
    context
  );

  const debouncedHandler = debounce((fn, e, value) => {
    if (!isNil(fn)) fn(e, value);
  }, local.Debounce.value);

  const inputRef = useRef<HTMLInputElement>(null);
  const gridCtx = useContext(GridStackContext);
  const handlers = setupHandlers(config, context);
  const handlersKeys = Object.keys(handlers);

  const inputProps: any = {};
  const InputProps: any = {};

  if (config.Multiline && config.MultilineAutosize) {
    inputProps.inputComponent = TextareaAutosize;

    if (!isNil(config.MinRows)) {
      inputProps.minRows = config.MinRows;
    }

    if (!isNil(config.MaxRows)) {
      inputProps.maxRows = config.MaxRows;
    }
  }

  if (!isNil(config.Min)) inputProps.min = config.Min;

  if (!isNil(config.Max)) inputProps.max = config.Max;

  if (!isNil(config.MaxLength)) inputProps.maxLength = config.MaxLength;

  if (!isNil(config.Adornment) && !isNil(config.Adornment.Position)) {
    const p = config.Adornment.Position.toLowerCase();
    const icon = config.Adornment.Icon?.IconName ?? "Search";

    InputProps[`${p}Adornment`] = (
      <InputAdornment position={p as "start" | "end"}>
        <NGIcon config={{ IconName: icon }} context={context} />
      </InputAdornment>
    );
  }

  local.InputProps = useSignal(InputProps);
  local.inputProps = useSignal(inputProps);
  // local.Valid = useSignal(valid);
  local.InDesignMode = useSignal(context?.InDesignMode ?? false);

  useSignalEffect(() => {
    if (inputRef.current && local.FocusOnDisplay.value) {
      inputRef.current.focus();
    }
  });

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  return (
    <>
      {local.Visible.value && (
        <TextField
          data-testid={getTestId(config)}
          data-type={config.__typename}
          inputRef={inputRef}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
          value={local.Value.value ?? ""}
          type={config.InputType ?? "text"}
          error={!local.Valid.value.success}
          label={getCustomLabel(config.Label)}
          helperText={!local.Valid.value.success && local.Valid.value.reasons?.map((r) => r.message).join(". ")}
          autoComplete={config.AutoComplete as string}
          autoFocus={config.AutoFocus as boolean}
          color={config.Color as any}
          disabled={gridCtx.InDesignMode || local.Disabled.value}
          fullWidth={config.FullWidth as boolean}
          margin={config.Margin as any}
          maxRows={local.MaxRows.value}
          minRows={local.MinRows.value}
          rows={local.Rows.value}
          multiline={local.Multiline.value}
          placeholder={config.Placeholder as string}
          required={config.Required as boolean}
          onKeyDown={onKeyDown}
          select={config.Select as boolean}
          size={config.Size as any}
          variant={config.Variant as any}
          inputProps={inputProps}
          InputProps={InputProps}
          {...ignoreCustomHandlers(handlers)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            switch (config.InputType) {
              case "number":
                {
                  const n = toNumber(e.target.value);
                  //TODO: validation
                  local.Value.value = isNaN(n) ? null : n;
                }
                break;
              case "date":
                {
                  const d = new Date(e.target.value);
                  //TODO: validation
                  local.Value.value = isNaN(d.getTime()) ? null : d;
                }
                break;
              default:
                local.Value.value = (e.target as HTMLInputElement).value;
                break;
            }
            // add support for custom onChange events
            handlersKeys.forEach((handler) => {
              if (handler.toLowerCase().includes("onchange")) {
                debouncedHandler(handlers[handler], e, local.Value.value);
                // handlers.onChange(e, local.Value.value);
              }
            });
          }}
        />
      )}
      {config.ContextAutocomplete && inputRef.current && (
        <NGContextAutocomplete
          config={config.ContextAutocomplete}
          context={{
            ...context,
            Element: inputRef.current,
          }}
        />
      )}
    </>
  );
}
