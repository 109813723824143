import { disableNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview";
import {
  draggable,
  dropTargetForElements,
  ElementDragPayload,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { attachClosestEdge, extractClosestEdge, Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { Signal, signal, useSignalEffect } from "@preact/signals-react";
import { meta, getState } from "./dataService";
import { isComponent, isContainer, isPage, isRepeater, isTabs } from "./metadataUtils";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { dropTargetForExternal, monitorForExternal } from "@atlaskit/pragmatic-drag-and-drop/external/adapter";
import { attachInstruction, extractInstruction, Instruction } from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { DragLocationHistory } from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import { log } from "./logger";
import { cloneJsonWithIDs } from "./metadataUtils";
import useCopyPasteListener from "../hooks/useCopyPasteListener";
type DragState = {
  type: string;
  closestEdge?: Edge | null;
  instruction?: Instruction | null;
};

type InstructionType = "reorder-above" | "reorder-below" | "make-child" | "reparent" | "instruction-blocked";

type DesignerState = {
  Selected: Signal<string | null>;
  ShowInvisibleComponents: Signal<boolean>;
  IgnoreSampleDataInEditor: Signal<boolean>;
  InteractionMode: Signal<boolean>;
};

export const designerState: DesignerState = {
  Selected: signal(null),
  ShowInvisibleComponents: signal(false),
  IgnoreSampleDataInEditor: signal(false),
  InteractionMode: signal(false),
};

export function setupDesigner(page) {
  meta.build(page);
}

const tag = "designer";

export const idleState = { type: "idle" };

export function setupDnd(el, handleEl, config): () => void {
  // if (!context.InDesignMode) return;
  const emptyCleanup = () => {
    // do nothing
  };

  // if (isComponent(config)) return emptyCleanup;

  if (!el) return emptyCleanup;

  const dropConfig = {
    element: el,
    getData: ({ input, element }) => {
      let data = { Id: config.Id, __typename: config.__typename };

      const targetNode = meta.getNode(config.Id);
      const parent = meta.getNode(targetNode?.parent);
      const isHorizontal = parent?.isHorizontal;

      // let allowedEdges: Edge[] = [];
      // if (isComponent(config) || isReference(config) || isContainer(config)) {
      //   allowedEdges = ["top", "left", "right", "bottom"];
      // } else if (isHorizontal) {
      //   allowedEdges = ["left", "right"];
      // } else {
      //   allowedEdges = ["top", "bottom"];
      // }

      const block = new Set();
      block.add("reparent");
      if (!(isContainer(config) || isComponent(config)) || isTabs(config)) {
        block.add("make-child");
      }
      if (isHorizontal) {
        // if (true) {
        block.add("reorder-above");
        block.add("reorder-below");
      }
      // if (isContainer(config)) {
      data = attachInstruction(data, {
        input,
        element,
        currentLevel: (targetNode?.level ?? 0) - 2,
        indentPerLevel: 0,
        mode: "standard",
        block: [...block] as InstructionType[],
      });
      // }
      data = attachClosestEdge(data, {
        input,
        element,
        allowedEdges: isHorizontal ? ["left", "right"] : [],
        // allowedEdges: isHorizontal ? ["left", "right"] : ["top", "bottom"],
        // allowedEdges,
        // allowedEdges: isHorizontal ? ["left", "right"] : [], // isHorizontal ? ["left", "right"] : ["top", "bottom"],
      });

      return data;
    },
    getIsSticky: () => {
      return false;
    },
    canDrop: (args) => {
      // const source = args.source.data;
      // const sourceNode = meta.getNode(source.Id);
      // const targetNode = meta.getNode(config.Id);
      // const sourceParentNode = meta.getNode(sourceNode.parent);
      // const targetParentNode = meta.getNode(targetNode.parent);

      // const isSelf = sourceNode?.Id == targetNode?.Id;
      // // const isTopLevelContainer = isContainer(targetNode) && isComponent(targetParentNode);
      // const isSibling = sourceNode?.parent == targetNode?.parent;
      // const isParent = sourceParentNode?.parent == targetNode?.parent;
      // // const instruction = extractInstruction(source);
      // // const closestEdge = extractClosestEdge(source);
      // const canDrop = !isSelf; // && (isContainer(targetNode) || isSibling || isParent);

      // // log.info(
      // //   tag,
      // //   "canDrop",
      // //   canDrop,
      // //   source.__typename,
      // //   config.__typename,
      // //   source.Id,
      // //   config.Id,
      // //   "top level",
      // //   isTopLevelContainer
      // // );

      // return canDrop;
      const sourceId = args?.source.data.Id;
      const self = config.Id == sourceId;

      const targetNode = meta.getNode(config.Id);
      if (targetNode?.pathIds.includes(sourceId)) {
        // Can't move item into one of its children
        return false;
      }
      const canDrop =
        (args.source.data.__typename === "TabItem" && config.__typename === "TabContainer") ||
        (args.source.data.__typename !== "TabItem" && config.__typename !== "TabContainer");

      return !self && canDrop;
    },
    // onDragEnter: ({ source, self }) => {
    //   dragState.value = {
    //     ...idleState,
    //     type: "item-over",
    //     instruction: extractInstruction(self.data),
    //     closestEdge: extractClosestEdge(self.data),
    //   };
    // },
    // onDrag: ({ source, self }) => {
    //   const instruction: Instruction | null = extractInstruction(self.data);
    //   const closestEdge = extractClosestEdge(self.data);
    //   const current = dragState.value;
    //   // skip re-render if edge is not changing
    //   if (
    //     current.type == "item-over" &&
    //     current?.instruction == instruction &&
    //     current?.closestEdge == closestEdge
    //     // designerState.Selected == config.Id
    //   ) {
    //     return;
    //   }
    //   dragState.value = { ...idleState, type: "item-over", instruction, closestEdge };
    // },
    // onDragLeave: () => {
    //   // closestEdge.value = null;
    //   dragState.value = idleState;
    // },
    // onDrop: () => {
    //   // closestEdge.value = null;
    //   dragState.value = idleState;
    // },
  };

  return combine(
    draggable({
      element: el,
      dragHandle: handleEl,
      // onDragStart: ({ source, location }) => {
      //   designerState.Selected.value = source.data.Id;
      // },
      canDrag: () => {
        // return designerState.Selected.value == config.Id;
        const node = meta.getNode(config.Id);
        // const isTopLevelComponent = isComponent(node) && isPage(parent);
        // const canDrag =
        //   !isTopLevelComponent &&
        //   !isComponent(parent) &&
        //   getTypename(parent) != "TabItem" &&
        //   getTypename(node) != "TabItem";
        // log.info(tag, "~~canDrag", node, parent, canDrag);
        // const isNotATabItem = getTypename(node) != "TabItem";
        // const doesNotVioldateDragRules = isNotATabItem;
        // console.log("doesNotVioldateDragRules", doesNotVioldateDragRules);

        return !!node?.operations.canDrag;
      },
      getInitialData: () => ({ Id: config.Id, __typename: config.__typename }),
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        disableNativeDragPreview({ nativeSetDragImage });
        // setCustomNativeDragPreview({
        //   // `x` and `y` can be any CSS value
        //   getOffset: pointerOutsideOfPreview({
        //     x: "8px",
        //     y: "calc(var(--grid) * 2)",
        //   }),
        //   render({ container }) {
        //     /* ... */
        //   },
        //   nativeSetDragImage,
        // });
      },
    }),
    dropTargetForElements(dropConfig),
    dropTargetForExternal({
      ...dropConfig,
      canDrop: ({ source }) => {
        const canDrop = source.types.includes("application/json"); // && config.__typename != "TabContainer";
        //console.log("~~canDrop", canDrop, source, config);

        return canDrop;
      },
    })
  );
}

export function hasDropRestriction({
  source,
  location,
}: {
  source: ElementDragPayload;
  location: DragLocationHistory;
}) {
  const target = location?.current?.dropTargets[0];
  if (!target?.data) return false;
  const instruction = extractInstruction(target?.data);
  const sourceElement = source?.data;
  const sourceHasParentRestriction = sourceElement?.parentRestrictions || null;

  const instructionType = instruction?.type;
  const parentTarget = target.data.parent as any;
  const targetElement = target.data;
  const targetHasChildRestriction = targetElement?.childRestrictions || null;
  const parentTargetHasChildRestriction = parentTarget?.childRestrictions || null;

  const restrictionForParent =
    !!sourceHasParentRestriction &&
    !(targetElement.type === sourceHasParentRestriction && instructionType === "make-child") &&
    !(parentTarget?.type === sourceHasParentRestriction && instructionType !== "make-child");

  const restrictionForTargetChild =
    !!targetHasChildRestriction &&
    instructionType === "make-child" &&
    !(sourceElement?.type === targetHasChildRestriction);

  const restrictionForParentTargetChild =
    !!parentTargetHasChildRestriction &&
    !(instructionType === "make-child") &&
    !(sourceElement?.type === parentTargetHasChildRestriction);

  return restrictionForParent || restrictionForTargetChild || restrictionForParentTargetChild;
}
//

// export function useDesignerForItem(config, context, ref, handleRef) {
//   const dragState = useSignal<DragState>(idleState);

//   useSignalEffect(() => {
//     return setupDnd(ref.current, handleRef.current, config, dragState);
//   });

//   return { dragState, selected: designerState.Selected, meta };
// }

export function useDesignerForOverlay(config, context, handlers) {
  function moveItem(context: any, sourceData, location) {
    if (!location.current.dropTargets.length) {
      return;
    }

    const { global } = getState(context);
    const page = global.Page?.value;

    const sourceNode = meta.getNode(sourceData.Id);
    // Keep searching through each possible target until we either perform a move or reorder
    const targets = location.current.dropTargets;
    for (let i = 0; i < targets.length; i++) {
      const targetData = targets[i].data;

      let targetNode = meta.getNode(targetData.Id);
      const targetParent = meta.getNode(targetNode?.parent);

      const closestEdgeOfTarget: Edge | null = extractClosestEdge(targetData);
      let instruction: Instruction | null = extractInstruction(targetData);

      if (isComponent(targetNode)) {
        // Drop into component
        instruction = { type: "make-child" } as Instruction;
        targetNode = meta.getNode(targetNode?.config.ComponentContainer.Id);
      }

      if (isRepeater(targetNode)) {
        // Drop into component
        instruction = { type: "make-child" } as Instruction;
        targetNode = meta.getNode(targetNode?.config.Items[0].Id);
      }

      if (isTabs(targetNode)) {
        // Drop into component
        instruction = { type: "make-child" } as Instruction;
        targetNode = meta.getNode(targetNode?.config.Toolbar.Id);
      }

      if (isPage(targetNode?.config)) {
        // Drop into page
        instruction = { type: "make-child" } as Instruction;
        targetNode = meta.getNode(targetNode?.config.Items[0].Id);
      }

      if (instruction?.type == "instruction-blocked" && closestEdgeOfTarget == null) return;

      let changed = false;

      if (sourceNode?.parent == targetNode?.parent && instruction?.type != "make-child") {
        // reorder within a container
        const items = targetParent?.config.Items;
        const sourceIndex = sourceNode?.index || 0;
        let targetIndex = targetNode?.index || 0;
        // reorder horizontally
        if (sourceIndex < targetIndex) {
          if (closestEdgeOfTarget) {
            // moving from left to right
            targetIndex += closestEdgeOfTarget == "right" ? 0 : -1;
          } else {
            // moving from top to bottom
            targetIndex += instruction?.type == "reorder-below" ? 0 : -1;
          }
        } else {
          if (closestEdgeOfTarget) {
            // moving from right to left
            targetIndex += closestEdgeOfTarget == "right" ? 1 : 0;
          } else {
            //moving from bottom to top
            targetIndex += instruction?.type == "reorder-below" ? 1 : 0;
          }
        }

        if (sourceIndex != targetIndex) {
          const item = items.splice(sourceIndex, 1)[0];
          items.splice(targetIndex, 0, item);
          log.info("~~drop", "reorder", instruction?.type, closestEdgeOfTarget, targetParent);
          changed = true;
        }
      } else {
        if (instruction?.type == "make-child") {
          // if (sourceNode.parent != targetNode?.Id) {
          // drop last into container
          const items = targetNode?.config.Items;
          let item = sourceData;
          if (sourceNode != null) {
            const sourceParent = meta.getNode(sourceNode.parent);
            item = sourceParent?.config.Items.splice(sourceNode?.index, 1)[0];
          }
          items.push(item);
          log.info(tag, "~~drop", "move-child", instruction?.type, closestEdgeOfTarget, targetNode);
          changed = true;
          // }
        } else {
          // add to specific position
          let target = targetParent;
          let index =
            (targetNode?.index ?? 0) +
            (instruction?.type == "reorder-below" || closestEdgeOfTarget == "right" ? +1 : 0);
          const makeChild = isComponent(targetParent) || targetParent?.__typename == "TabItem";
          if (makeChild) {
            target = targetNode;
            index = instruction?.type == "reorder-below" ? target?.config.Items.length - 1 : 0;
          }
          const items = target?.config.Items;

          let item = sourceData;
          if (sourceNode != null) {
            const sourceParent = meta.getNode(sourceNode.parent);
            item = sourceParent?.config.Items.splice(sourceNode?.index, 1)[0];
          }
          items.splice(index, 0, item);
          log.info(tag, "~~drop", "move-adjacent", instruction?.type, closestEdgeOfTarget, targetNode);
          changed = true;
        }
      }

      // AA-TODO: Perform immutable update
      if (changed) {
        global.Page.value = { ...page };
        handlers["onChange"](new Event("change"), global.Page.value);
        log.info(tag, "~~drop", global.Page.value);
      }
      break;
    }
  }

  useCopyPasteListener(handlers);

  useSignalEffect(() => {
    log.info(tag, "~~selection changed", designerState.Selected.value);
    const selected = meta.getNode(designerState.Selected.value)?.config;
    handlers["onSelected"](new Event("selected"), selected);
  });

  useSignalEffect(() => {
    // if (!context.InDesignMode) return;

    return combine(
      monitorForElements({
        // onDragStart: ({ source, location }) => {
        //   designerState.Selected.value = source.data.Id;
        // },
        onDrop: ({ source, location }) => {
          const sourceData = source.data;
          moveItem(context, sourceData, location);
        },
      }),
      monitorForExternal({
        onDrop: ({ source, location }) => {
          const json = source.getStringData("application/json");
          if (json == null) return;
          const item = JSON.parse(json);
          if (item.__typename != "LibraryItem") return;
          const sourceData = cloneJsonWithIDs(item.Template, {});
          moveItem(context, sourceData, location);
        },
      })
    );
  });

  return { selected: designerState.Selected };
}
