import { Icon, Avatar, Maybe } from "../../../resolvers-types";
import { INGAvatarProps } from "../../library/NGFieldExtensions";
import { Avatar as MUIAvatar } from "@mui/material";

import { useSignal, signal } from "@preact/signals-react";
import { useContext, useEffect } from "react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import {
  getInitials,
  getTestId,
  getsxObject,
  isNullOrEmpty,
  getClassName,
  normalizeSessionUrl,
} from "../../library/utils";
import { Dictionary, isNil } from "lodash";
import { log } from "../../library/logger";
import { setupImageDetails, convertToSizedImageFilename } from "../ComponentUtils";

const tag = "NGAvatar";

export default function NGAvatar({ config, context }: INGAvatarProps) {
  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
      Visible: useSignal(config.Visible ?? true),
      AlternativeText: useSignal(config.AlternativeText || ""),
      Size: useSignal(config.Size || "Medium"),
      Path: useSignal(config.Path || ""),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Source: useSignal(config.Source ?? null), // Asset, Vault, External
    },
    context
  );

  log.info(tag, `render ${local.Value.value} `, config);

  const url = useSignal<string | undefined>("");

  useEffect(() => {
    if (!local.Visible.value) return;

    // console.log("NGAvatar-start", local.AlternativeText.value, url.value);

    if (isNullOrEmpty(local.Value.value)) {
      url.value = "/avatar/noimage.png";
      //   console.log("NGAvatar-undefined", local.AlternativeText.value, url.value);
      return;
    }
    const value = local.Value.value;

    setupImageDetails(local, value, url, convertToSizedImageFilename);

    // console.log("NGAvatar-end", local.AlternativeText.value, url.value);
  }, [local.Visible.value, local.Value.value, local.Path.value, local.Size.value, url, local.Source, local]);

  const handlers = setupHandlers(config, context);

  return (
    <>
      {local.Visible.value && url.value && (
        <MUIAvatar
          data-testid={getTestId(config)}
          sx={getsxObject(local.Style.value)}
          src={url.value}
          alt={local.AlternativeText.value as string}
          className={getClassName(local.Classes)}
          {...handlers}
        >
          {getInitials(local.AlternativeText.value)}
        </MUIAvatar>
      )}
    </>
  );
}
