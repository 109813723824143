import { SyntheticEvent, useCallback, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { ReadonlySignal, useComputed, useSignal, useSignalEffect } from "@preact/signals-react";
import { debounce, isArray, isBoolean, isEqual, isNil, isObject, isUndefined, max, min, toNumber } from "lodash-es";
import OdataProvider from "../../library/ODataProvider";
import { OdataQueryExtendFull } from "../../library/ODataProvider/types";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import {
  AdvancedFilterModule,
  CellStyle,
  ClipboardModule,
  ClientSideRowModelModule,
  ColumnMovedEvent,
  ColumnPinnedEvent,
  CellValueChangedEvent,
  ColumnPivotChangedEvent,
  ColumnsToolPanelModule,
  ColumnRowGroupChangedEvent,
  ColumnValueChangedEvent,
  ColumnVisibleEvent,
  CsvExportModule,
  CsvExportParams,
  DomLayoutType,
  ExcelExportModule,
  ExcelExportParams,
  ExpandOrCollapseAllEvent,
  FiltersToolPanelModule,
  GridOptions,
  IAggFuncParams,
  IDateFilterParams,
  LicenseManager,
  MasterDetailModule,
  MenuModule,
  ModuleRegistry,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowClickedEvent,
  RowGroupingModule,
  RowValueChangedEvent,
  SelectionChangedEvent,
  SetFilterModule,
  SideBarModule,
  SizeColumnsToFitGridStrategy,
  SparklinesModule,
  StatusBarModule,
  GridReadyEvent,
  ServerSideRowModelModule,
  RowModelType,
  IGetRowsParams,
  ColumnResizedEvent,
} from "ag-grid-charts-enterprise";
import { Button, IActionTrigger, LayoutItem, List, ListColumn, Maybe, SimpleContainer } from "../../../resolvers-types";
import NGButton from "../NGButton/NGButton";
import NGLayoutItem from "../../generators/NGLayoutItem";
import {
  IButtonMethods,
  INGListProps,
  RowGroupPanelShowOptions,
  RuntimeContext,
} from "../../library/NGFieldExtensions";
import {
  GetConvertedLabelsForListFromSiteSettings,
  GetFormatFromSite,
  getScope,
  getState,
  mapFields,
  setupHandlers,
  setupLocalState,
  updateItemContext,
} from "../../library/dataService";
import { matchTypename } from "../../library/metadataUtils";
import { generateUID, getCustomLabel, getTestId, isNullOrEmpty, traverse } from "../../library/utils";
import CellRendererList from "./NGCellRendererList";
import {
  addBlankRows,
  addRowPerPageToSelector,
  checkboxSelection,
  CustomColDef,
  defaultExportParams,
  defaultRowHeight,
  defaultRowsPerPage,
  defaultGroupColumn,
  excelStyles,
  fromColDefsToConfig,
  getBindings,
  getDataTypeFilter,
  getFormatDataType,
  getRowId,
  getRows,
  IAnyObject,
  removePageElements,
  getFilterParams,
  getCustomFilters,
  resolveClasses,
} from "./NGListUtils";
import { callOdataQuery, ODataQueryOptions } from "../../library/oDataUtils";

import "ag-grid-charts-enterprise/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-charts-enterprise/styles/ag-theme-quartz.css"; // Mandatory CSS required by the Data Grid
import "./NGList.css";
import QueryRequest from "./oDataQueryBuilder";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-064288}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{JBI_Holdings}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{JBI}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{JBI}_need_to_be_licensed___{JBI}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{29_July_2025}____[v3]_[0102]_MTc1Mzc0MzYwMDAwMA==bd659fcc281dec57d192f14cfd09e0c3"
);

ModuleRegistry.registerModules([
  AdvancedFilterModule,
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  CsvExportModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  MasterDetailModule,
  MenuModule,
  MultiFilterModule,
  RangeSelectionModule,
  RichSelectModule,
  RowGroupingModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
  SparklinesModule,
]);

type IPinned = "left" | "right";
type IOnColumnUpdate =
  | ColumnRowGroupChangedEvent
  | ColumnPivotChangedEvent
  | ColumnMovedEvent
  | ColumnPinnedEvent
  | ColumnVisibleEvent
  | ColumnValueChangedEvent;

const tag = "NGList";

export default function NGList({ config, context }: INGListProps) {
  const GridRef = useRef<AgGridReact<IAnyObject>>(null);
  const ContainerRef = useRef<HTMLDivElement>(null);
  const GridContainerRef = useRef<HTMLDivElement>(null);
  const AdvanceFilterRef = useRef<HTMLDivElement>(null);
  const QuickFilterRef = useRef<HTMLInputElement>(null);
  const testId = getTestId(config);

  const currentContext = updateItemContext(context, config, { GridRef, QuickFilterRef });

  const local = setupLocalState(
    config,
    {
      Columns: useSignal([]),
      ListColumns: useSignal(config.ListColumns ?? []),
      Rows: useSignal(config.Rows ?? []),
      Loading: useSignal(config.Loading ?? false),
      QuickFilterText: useSignal(config.QuickFilterText ?? undefined),
      Service: useSignal(config.Service ?? {}),
      Refresh: useSignal(config.Refresh ?? false),
      ClearGridState: useSignal(config.ClearGridState ?? false),
      RowsPerPage: useSignal(config.RowsPerPage ?? defaultRowsPerPage),
    },
    currentContext
  );
  const location = useLocation();

  function getColumns(
    cols: Maybe<ListColumn>[] | undefined | null,
    listCols: Maybe<ListColumn>[] | undefined | null,
    context: RuntimeContext,
    config: List
  ): CustomColDef[] {
    const MUICols: CustomColDef[] = [];
    const allCols = [...(cols ?? []), ...(isArray(listCols) ? listCols : [])];

    if (isNil(allCols) || allCols.length === 0) return MUICols;

    allCols.forEach((col) => {
      if (!col) return;

      col = getBindings({ context, config: col }) as ListColumn;
      const format = col.FormatName ? GetFormatFromSite(col.FormatName) : null;
      const newCol: CustomColDef = {
        field: col.Name as string,
        valueFormatter: (p) => getFormatDataType(format, p.value, col.DataType),
        filter: col.DisableFiltering === true ? false : getDataTypeFilter(col.DataType),
        editable: col.Editable as boolean,
        sortable: col.DisableSorting === true ? false : true,
        hide: !isUndefined(col.Visible) ? !col.Visible : false,
        headerName: getCustomLabel(col.HeaderName as string),
        suppressHeaderMenuButton: !!col.SuppressHeaderMenu,
        cellStyle: col.Style as CellStyle,
        cellClass: resolveClasses(col.Classes, col.FormatName),
        colId: col.Name ?? col.Id ?? generateUID(),
        flex: col.Flex ?? undefined,
        cellDataType: col.DataType ?? undefined,
        rowGroup: col.RowGroup ?? undefined,
        minWidth: col.ColumnMinimumWidth ?? undefined,
        width: col.ColumnFixedWidth ?? undefined,
        aggFunc: col.AggFunc,
        autoSize: col.AutoSize ?? false,
        defaultAggFunc: col.DataType === "number" ? "sum" : "first",
        pivot: col.Pivot ?? undefined,
        pinned: col.Pinned as IPinned,
        listCol: col,
        enableRowGroup: config.DisabledColumnGrouping ? col.DisableGrouping === false : !col.DisableGrouping,
        filterParams: getFilterParams(col.DataType),
      };

      if (col.CellLayout || col.DisplayAsChip) {
        newCol.cellRenderer = (params: CustomCellRendererProps) => {
          const clonedConfig = getBindings({ params, context, config: col.CellLayout });
          if (col.DisplayAsChip) {
            (clonedConfig as any).DisplayAsChip = col.DisplayAsChip;
            (clonedConfig as any).__typename = "Label";
            (clonedConfig as any).ChipClasses = col.ChipClasses;
          }

          return <NGLayoutItem config={clonedConfig} context={context} />;
        };
      }

      if (isArray(col.CellRendererItems) && col.CellRendererItems.length) {
        newCol.cellRenderer = (params: CustomCellRendererProps) => (
          <CellRendererList {...params} context={context} components={col.CellRendererItems} />
        );
      }

      MUICols.push(newCol);
    });

    return MUICols;
  }

  const handlers = setupHandlers(config, currentContext, location);

  function onInput() {
    GridRef.current?.api.setGridOption("quickFilterText", QuickFilterRef.current?.value);
    local.QuickFilterText.value = QuickFilterRef.current?.value;
  }

  function convertLegacyGrid() {
    return {
      __typename: "SimpleContainer",
      Id: generateUID(),
      IgnoreLayout: true,
      Style: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minHeight: "20px",
        flex: 1,
        justifyContent: "space-between",
      },
      Items: [
        // add items from config.Toolbar.Items
        ...((config as any)?.Toolbar?.Items ?? []),
        ...(config?.Items ?? []),
      ],
    } as SimpleContainer;
  }

  function EditToolbar() {
    const hasTopContainer = config.Items && config.Items[0] && config.Items[0]?.__typename == "SimpleContainer";

    // Toolbar at the top level always has a container
    const container = hasTopContainer ? (config.Items as any)[0] : convertLegacyGrid();

    if (!hasTopContainer) config.Items = [container];

    function addItem(item: any, position: number) {
      if (isNil(container.Items)) container.Items = [];

      if (position === -1) {
        container.Items.push(item);
      } else {
        container.Items.splice(position, 0, item);
      }
    }

    const quickFilterRefs: any[] = [];
    traverse(config.Items, 0, quickFilterRefs, "ListQuickFilter");

    const exportRefs: any[] = [];
    traverse(config.Items, 0, exportRefs, "ListExportButton");

    const hasExportButton = exportRefs.length > 0;
    const hasQuickFilter = quickFilterRefs.length > 0;

    if (!hasExportButton)
      addItem(
        {
          __typename: "ListExportButton",
          EnableCSV: true,
          EnableExcel: true,
          Visible: (config as any).ShowExport !== false && (config as any).Toolbar?.ShowExport !== false,
        },
        0
      );

    if (!hasQuickFilter) {
      const quickFilterAction = config?.Actions?.find((a) => a?.Trigger === "onQuickFilterChange");
      addItem(
        {
          __typename: "ListQuickFilter",
          Actions: [quickFilterAction],
          Adornment: { Position: "Start", Icon: { IconName: "Search" } },
          Visible: (config as any).ShowQuickFilter !== false,
        },
        -1
      );
    }

    quickFilterRefs.forEach((ref) => {
      ref.onInput = onInput;
    });

    return (
      <>
        {config.Items?.map((item, key) => (
          <NGLayoutItem key={key} config={item as LayoutItem} context={currentContext} />
        ))}
      </>
    );
  }

  function BottomButtons() {
    if (!config?.SpreadsheetModeOptions?.ShowAddRowsButton) return null;

    const addRowsToSheetButton: Button = {
      __typename: "Button",
      Id: `${config.Id}_AddRowsButton`,
      Label: "Add 5 Rows",
      StartIcon: { IconName: "Add" },
      Actions: [
        {
          Trigger: "onClick",
          preHandler: (
            handlerName: string,
            action: IActionTrigger,
            e: SyntheticEvent,
            data: object,
            formCtx: unknown
          ) => {
            const newRows = addBlankRows(5);
            local.Rows.value = [...local.Rows.value, ...newRows];
            console.log(tag, "onClick", { handlerName, e, action }, data, formCtx);
          },
        },
      ],
    };

    return (
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <NGButton config={addRowsToSheetButton} methods={{} as IButtonMethods} context={currentContext} />
      </Box>
    );
  }

  function handleOnRowClicked(params: RowClickedEvent<IAnyObject>) {
    handlers["onRowClick"]?.(null, params.data);
  }

  function onSelectionChanged(params: SelectionChangedEvent<IAnyObject>) {
    const rows = params.api.getSelectedRows();
    // handle select all current page case
    const selectedNodes = params.api
      .getRenderedNodes()
      .filter((n) => n.isSelected());

    const selectedRows = rows.length ? rows : selectedNodes.map((n) => n.data);

    handlers["onSelectionChanged"]?.(null, selectedRows);
  }

  const onRowValueChanged = useCallback(
    (params: RowValueChangedEvent) => {
      const clonedRows = [...local.Rows.value];
      clonedRows[params.rowIndex ?? 0] = params.data;
      handlers["onRowUpdate"]?.(null, { Rows: clonedRows });
    },
    [handlers, local.Rows.value]
  );

  const onCellValueChanged = useCallback(
    (params: CellValueChangedEvent) => {
      const clonedRows = [...local.Rows.value];
      clonedRows[params.rowIndex ?? 0] = params.data;
      if (!isNil(handlers["onCellUpdate"])) handlers["onCellUpdate"](null, { Rows: clonedRows });
    },
    [handlers, local.Rows.value]
  );

  const handleGridEditorEvent = useCallback(
    (setFn: () => List | undefined) => {
      const { parentState } = getState(currentContext);
      if (handlers["onGridConfigurationChange"] && matchTypename("List", parentState?.["SelectedComponent"]?.value)) {
        const Config = setFn();
        if (Config) {
          // remove grid configuration change event to avoid infinite loop
          Config.Actions = Config?.Actions?.filter((a) => a?.Trigger !== "onGridConfigurationChange");
          handlers["onGridConfigurationChange"](new Event("change"), { Config });
        }
      }
    },
    [handlers, currentContext]
  );

  const onColumnManuallyResized = useCallback(
    (params: ColumnResizedEvent<IAnyObject>) => {
      if (params.source === "uiColumnResized") {
        const colDef = params.column?.getColDef();
        const listColumns = [...local.ListColumns.value];
        const colIndex = listColumns.findIndex((c) => c.Id === colDef?.colId);
        if (colIndex !== -1) {
          listColumns[colIndex].ColumnFixedWidth = params.column?.getActualWidth();
        }

        handleGridEditorEvent(() => ({
          ...config,
          ListColumns: listColumns,
        }));
      }
    },
    [config, local.ListColumns.value, handleGridEditorEvent]
  );

  const onColumnsUpdate = useCallback(
    (event: IOnColumnUpdate) => {
      handleGridEditorEvent(() => {
        const columnsDefinitions = event?.api?.getColumnDefs();
        const ListColumns = fromColDefsToConfig(columnsDefinitions as CustomColDef[]);
        if (!isEqual(ListColumns, local.ListColumns.value)) {
          return { ...config, ListColumns };
        }
        return undefined;
      });
    },
    [config, local.ListColumns.value, handleGridEditorEvent]
  );

  const cols: ReadonlySignal<CustomColDef[]> = useComputed(() =>
    getColumns(local.Columns.value, local.ListColumns.value, currentContext, config)
  );

  const rowData: ReadonlySignal<IAnyObject[]> = useComputed(() => getRows(local.Rows.value));

  const gridOptions: GridOptions<IAnyObject> = useMemo(
    () => ({
      onColumnPivotModeChanged(event) {
        event.api.sizeColumnsToFit();
      },
      statusBar: {
        statusPanels: [
          { statusPanel: "agTotalRowCountComponent" },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent", align: "left" },
          { statusPanel: "agAggregationComponent", align: "right" },
        ],
      },
      rowDragManaged: true,
      rowDragMultiRow: true,
      popupParent: GridContainerRef.current,
      pivotPanelShow: "always",
      suppressColumnMoveAnimation: false,
      enableRtl: /[?&]rtl=true/.test(window.location.search),
      enableRangeSelection: true,
      enableFillHandle: true,
      suppressClearOnFillReduction: false,
      groupSelectsChildren: true,
      columnTypes: {
        currencyType: {
          useValueFormatterForExport: false,
        },
      },
      getBusinessKeyForNode: (node) => (node.data ? node.data.name : ""),
      initialGroupOrderComparator: ({ nodeA, nodeB }) => {
        if (!nodeA?.key || !nodeB?.key) return 0;
        return nodeA?.key < nodeB?.key ? -1 : nodeA?.key > nodeB?.key ? 1 : 0;
      },
      advancedFilterParent: ContainerRef.current,
      excelStyles: excelStyles(),
      localeText: GetConvertedLabelsForListFromSiteSettings(),
    }),
    []
  );

  const handleSidebarOpened = (e) => {
    if (e.source !== "sideBarButtonClicked" && e.visible && !config.SideBarOpenByDefault) {
      e.api.closeToolPanel();
    }
  };

  const onFirstDataRendered = useCallback(() => {
    const colDefs = GridRef.current?.api.getColumnDefs() ?? [];
    if (config.EnableSideBar) {
      for (const def of colDefs as unknown as CustomColDef[]) {
        if (def?.pivot) {
          GridRef.current?.api.setGridOption("pivotMode", true);
          break;
        }
      }
    }

    const autoSizeColumns = colDefs.filter((c: CustomColDef) => c?.autoSize && c?.colId);
    if (autoSizeColumns.length)
      GridRef.current?.api.autoSizeColumns(autoSizeColumns.map((c: CustomColDef) => c.colId!));

    GridRef.current?.api.closeToolPanel();
  }, [config]);

  const onExpandOrCollapseAll = useCallback(
    (e: ExpandOrCollapseAllEvent) => {
      handleGridEditorEvent(() => ({
        ...config,
        GroupingsCollapsed: e.source === "collapseAll",
      }));
    },
    [config, handleGridEditorEvent]
  );

  const resolveServiceFields = useCallback(
    (request: IGetRowsParams): ODataQueryOptions => {
      const service = local.Service.value;
      if (service?.Fields) {
        const { state, form, parentState } = getState(currentContext);

        const scope = getScope(currentContext, config, state, form, {}, parentState);
        const [params, isValid] = mapFields(service.Fields, scope);
        if (isValid) {
          const { Filter, GroupBy, Aggregate, OrderBy: OrderByParam, OData } = params;
          const sortModel = request.sortModel;
          // filter out repeated sort columns
          const OrderBy = OrderByParam?.filter((o) => !sortModel.find((s) => o.includes(s.colId)));
          const queryRequest = new QueryRequest();
          queryRequest.Filter = Filter;
          queryRequest.GroupBy = GroupBy;
          queryRequest.Aggregate = Aggregate;
          queryRequest.OrderBy = OrderBy;
          queryRequest.OData = OData;
          const odataQuery = queryRequest.toODataQuery();
          return odataQuery as ODataQueryOptions;
        }
      }
      return {};
    },
    [config, currentContext, local.Service.value]
  );

  const getNextDay = (dateFrom: Date): Date => {
    const newDate = new Date(dateFrom); // Create a copy to avoid mutating the original
    newDate.setDate(newDate.getDate() + 1); // Add one day
    return newDate;
  };

  const getDateOnly = (d: string): string => {
    if (isNullOrEmpty(d)) return d;

    if (d.length > 10) {
      return d.substring(0, 10);
    }

    return d;
  };

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (document.documentElement.clientWidth <= 1024) {
        event.api.closeToolPanel();
      }
      const customFilters = getCustomFilters(cols.value);

      const provider = new OdataProvider({
        isCaseSensitiveStringFilter: true,
        callApi: async (options) => {
          const serviceConfig = { ...local.Service.value };
          return await callOdataQuery({ options, context: currentContext, serviceConfig, config });
        },
        beforeRequest(query, provider, request) {
          Object.entries(request.filterModel).forEach(([key, value]) => {
            if ((value as any).filterType !== "date") return;

            const datatype = local.ListColumns.value.find((c) => c.Id === key)?.DataType;

            const f = value as any;

            if (datatype !== "datetime") {
              if (!isNullOrEmpty(f.dateFrom)) f.dateFrom = getDateOnly(f.dateFrom);
              if (!isNullOrEmpty(f.dateTo)) f.dateTo = getDateOnly(f.dateTo);
              return;
            }

            switch (f.type) {
              case "equals":
                f.dateTo = getNextDay(new Date(f.dateFrom));
                f.type = "inRange";
                break;
            }
          });

          const { $filter, $orderby } = resolveServiceFields(request as IGetRowsParams);
          provider.toDateTime = (value: string): string | null => {
            if (value?.length <= 10) return value;

            return new Date(value).toUTCWithoutTimezone();
          };
          provider.toQuery = (options: OdataQueryExtendFull): string => {
            const path: Record<string, string> = {};
            const orderBy = [...(options.sort ?? []), $orderby ?? ""].filter(Boolean).join(",");
            const filter = [...(options.filter ?? []), $filter ?? ""].filter(Boolean).join(" and ");
            if (options.count) {
              path["$count"] = true + "";
            }
            if (options.skip) {
              path["$skip"] = options.skip + "";
            }
            if (options.top) {
              path["$top"] = options.top + "";
            }
            if (orderBy) {
              path["$orderby"] = orderBy;
            }
            if (filter) {
              path["$filter"] = filter;
            }
            if (options.apply && options.apply.length > 0) {
              path["$apply"] = options.apply.join("/");
            }
            if (options.expand && options.expand.length > 0) {
              path["$expand"] = options.expand.join(",");
            }
            if (options.select && options.select.length > 0) {
              path["$select"] = options.select.join(",");
            }

            let query = "";
            if (Object.keys(path).length > 0) {
              query = "?" + new URLSearchParams(path).toString();
            }
            return query;
          };
          query.select = cols.value.map((c) => c.field).filter((field) => field !== undefined);
        },
        afterLoadData(options, rowData) {
          // when new page returns 0 rows, rowData.length === 0, need to take into acount all rows
          const rowsCount = GridRef.current?.api.getDisplayedRowCount();
          console.log(tag, "afterLoadData", { options, rowData, event, rowsCount });
          rowsCount ? event.api.hideOverlay() : event.api.showNoRowsOverlay();
        },
        customFilters,
      });
      event.api.setGridOption("serverSideDatasource", provider);

      removePageElements(testId ?? "", [".ag-paging-row-summary-panel, .ag-paging-description"]);
    },
    [cols.value, config, currentContext, local.ListColumns.value, local.Service.value, resolveServiceFields, testId]
  );

  useSignalEffect(() => {
    if (isBoolean(local.Refresh.value) && local.Refresh.value) {
      GridRef.current?.api.refreshServerSide({ route: undefined, purge: false });
      local.Refresh.value = false;
    } else if (isObject(local.Refresh.value) && local.Refresh.value?.RefreshRows) {
      const { Purge = false, Route = undefined } = local.Refresh.value;
      GridRef.current?.api.refreshServerSide({ route: Route, purge: Purge });
      local.Refresh.value = { RefreshRows: false, Purge: false, Route };
    }
  });

  useSignalEffect(() => {
    if (local.ClearGridState.value) {
      GridRef.current?.api.setFilterModel(null);
      GridRef.current?.api.deselectAll();
      local.ClearGridState.value = false;
    }
  });

  return (
    <div data-testid={testId} data-type={config.__typename} ref={GridContainerRef} style={{ width: "100%" }}>
      <EditToolbar />
      <div ref={AdvanceFilterRef} />
      <div
        className="ag-theme-quartz"
        style={{
          width: "100%",
          height:
            config.GridHeight ??
            (config.GridLayout === "autoHeight" ? "100%" : `${defaultRowHeight * Number(local.RowsPerPage.value)}px`),
        }}
      >
        <AgGridReact
          domLayout={(config.GridLayout as DomLayoutType) ?? "normal"}
          sideBar={
            config.EnableSideBar
              ? {
                  toolPanels: ["columns", "filters"],
                  position: "right",
                  defaultToolPanel: "",
                  hiddenByDefault: false,
                }
              : undefined
          }
          onToolPanelVisibleChanged={handleSidebarOpened}
          getChartToolbarItems={() => ["chartDownload"]}
          defaultColDef={{
            floatingFilter: !!config.ShowFloatingFilter,
            enableRowGroup: config.DisabledColumnGrouping !== true,
            editable: !!config.EnableEditing,
            filter: "agTextColumnFilter",
            flex: 1,
            enableValue: true,
            enablePivot: true,
            enableCellChangeFlash: true,
            cellClass: config.ColumnsClasses ?? undefined,
            headerClass: config.ColumnsHeaderClasses ?? undefined,
          }}
          rowSelection={
            config.ShowCheckboxSelection
              ? {
                  mode: "multiRow",
                  isRowSelectable: () => true,
                  checkboxes: (params) =>
                    config.ShowCheckboxSelection ? checkboxSelection(params, GridRef.current?.api) : false,
                  headerCheckbox: !!config.ShowCheckboxSelection,
                  hideDisabledCheckboxes: true,
                  selectAll: "currentPage",
                  enableClickSelection: config.EnableRowClickSelection ?? false,
                }
              : undefined
          }
          editType={config.EditMode === "fullRow" ? "fullRow" : undefined}
          getChildCount={(data) => data && data.childCount}
          cellSelection={!!config.EnableCellSelection}
          suppressCellFocus={!config.EnableCellSelection}
          pivotMode={!!config.PivotMode}
          ref={GridRef}
          rowData={rowData.value}
          columnDefs={cols.value}
          loading={local.Loading.value}
          getRowId={getRowId}
          autoSizeStrategy={
            config.RowsAutoSizeStrategy
              ? ({
                  type: config.RowsAutoSizeStrategy ?? "fitGridWidth",
                } as SizeColumnsToFitGridStrategy)
              : undefined
          }
          autoGroupColumnDef={defaultGroupColumn(config)}
          rowGroupPanelShow={(config.ShowGroupingPanel as RowGroupPanelShowOptions) ?? undefined}
          suppressAggFuncInHeader
          groupDefaultExpanded={config.GroupingsCollapsed ?? true ? 0 : -1}
          onRowClicked={handleOnRowClicked}
          onColumnValueChanged={onColumnsUpdate}
          onSelectionChanged={onSelectionChanged}
          onColumnResized={debounce(onColumnManuallyResized, 300)}
          onCellValueChanged={onCellValueChanged}
          rowHeight={config.RowHeight ?? defaultRowHeight}
          paginationPageSize={Number(local.RowsPerPage.value)}
          paginationAutoPageSize={config.PaginationAutoPageSize ?? undefined}
          paginationPageSizeSelector={
            config.ShowPageSizeSelector ? addRowPerPageToSelector(local.RowsPerPage.value) : false
          }
          pagination={!!config.ShowPagination}
          gridOptions={gridOptions as unknown as GridOptions<unknown>}
          defaultCsvExportParams={defaultExportParams as CsvExportParams}
          defaultExcelExportParams={defaultExportParams as unknown as ExcelExportParams}
          enableAdvancedFilter={!!config.ShowAdvancedFilter}
          advancedFilterParent={AdvanceFilterRef.current}
          onFirstDataRendered={onFirstDataRendered}
          readOnlyEdit={!!config.ReadOnlyEdit}
          onRowValueChanged={onRowValueChanged}
          onExpandOrCollapseAll={onExpandOrCollapseAll}
          onColumnPinned={onColumnsUpdate}
          onColumnMoved={debounce(onColumnsUpdate, 300)}
          onColumnPivotChanged={onColumnsUpdate}
          onColumnVisible={onColumnsUpdate}
          cacheBlockSize={Number(local.RowsPerPage.value)}
          maxBlocksInCache={10}
          onGridReady={onGridReady}
          rowModelType={(config.RowModelType as RowModelType) ?? "clientSide"}
          aggFuncs={{
            sum: (params: IAggFuncParams) => {
              let sum = 0;
              params.values.forEach((value) => {
                if (typeof value === "number" || params.colDef.cellDataType === "number") sum += toNumber(value);
              });
              return sum ?? undefined;
            },
            max: (params: IAggFuncParams) => {
              return max(params.values) ?? undefined;
            },
            min: (params: IAggFuncParams) => {
              return min(params.values) ?? undefined;
            },
          }}
        />
      </div>
      <BottomButtons />
    </div>
  );
}
