import { useState, useEffect, useMemo, useCallback } from "react";
import { useSignal } from "@preact/signals-react";
import { v4 as uuidv4 } from "uuid";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { INGRichTextEditorProps } from "../../library/NGFieldExtensions";
import {
  camelCaseToTitleCase,
  formatFileName,
  getRepositoryAssetsBaseUrl,
  getTempFileName,
  getTestId,
} from "../../library/utils";

import FroalaEditor from "froala-editor";
// Require Editor JS files.
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/quick_insert.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/file.min.js";
import "froala-editor/js/plugins/link.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/themes/dark.min.css";
import "froala-editor/css/plugins/draggable.min.css";
import "froala-editor/css/third_party/embedly.min.css";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/file.min.css";
import "froala-editor/css/plugins/video.min.css";

import { client } from "../../library/nats-client";
import { isNil } from "lodash-es";
import { Buffer } from "buffer";
import { NGContextAutocomplete } from "../../components/NGContextAutocomplete/NGContextAutocomplete";

window.Buffer = Buffer;

type Editor = typeof FroalaEditor;

enum UploadType {
  Image = "image",
  File = "file",
  Video = "video",
}

export default function NGRichTextEditor({
  config,
  context,
}: INGRichTextEditorProps) {
  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
      Value: useSignal(config.Value ?? ""),
      Disabled: useSignal(config.Disabled ?? false),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? ""),
    },
    context
  );
  const fileName = useSignal<string | null>(null);
  const editor = useSignal<Editor>({} as Editor);
  const addedLinks = useSignal<Map<string, string>>(new Map());

  const handlers = setupHandlers(config, context);

  const saveOnAssets = async (file, type) => {
    if (!file.name) {
      fileName.value = `print-Screen-${uuidv4()}.png`;
    } else {
      fileName.value = file.name;
    }
    const id = getTempFileName(file.type);
    const reader = file.stream().getReader();
    const stream = new ReadableStream({
      start(controller) {
        return pump();
        function pump() {
          return reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            return pump();
          });
        }
      },
    });
    try {
      const objPutResponse = await client.objPut(
        "temp",
        id,
        "Temp file",
        file.size,
        stream
      );
      if (!objPutResponse.success) {
        const msg = objPutResponse.reasons?.map((x) => x.message).join("\n");
        console.error("Error", msg);
      } else {
        const response = await client.request("project.asset.save", {
          Description: file.name,
          File: {
            Id: id,
            ContentType: file.type,
            LastModified: file.lastModifiedDate,
            Name: fileName.value,
            Type: "limbo",
            Size: file.size,
          },
          Path: null,
          Type: camelCaseToTitleCase(type),
        });

        if (!response.success) {
          const msg = response.reasons?.map((x) => x.message).join("\n");
          console.error("Error", msg);
          return;
        }
        return fileName.value;
      }
    } catch (error) {
      console.error("Unexpected Error", error);
    }
  };

  const handleModelChange = useCallback(
    (e) => {
      local.Value.value = e;
      if (!isNil(handlers.onChange)) handlers.onChange(null, local.Value.value);
    },
    [handlers, local.Value]
  );

  const formatAssetUrl = useCallback(
    async (file, uploadType: UploadType) => {
      const fileNameFormatted = formatFileName(fileName.value);
      const extension = fileName.value?.split(".").pop();
      const assetUrl = getRepositoryAssetsBaseUrl();
      if (file.src) {
        file.src = "";
        file.src = `${assetUrl}${camelCaseToTitleCase(
          uploadType
        )}s/${fileNameFormatted}.${extension}`;
      } else {
        file.href = "";
        file.href = `${assetUrl}${camelCaseToTitleCase(
          uploadType
        )}s/${fileNameFormatted}.${extension}`;
      }
    },
    [fileName.value]
  );

  const toolbarButtonsMap = useMemo(
    () => ({
      bold: "Bold",
      italic: "Italic",
      underline: "Underline",
      strikeThrough: "Strikethrough",
      insertImage: "Insert Image",
      insertVideo: "Insert Video",
      insertLink: "Insert Link",
      insertFile: "Insert File",
      alignLeft: "Align Left",
      alignCenter: "Align Center",
      alignRight: "Align Right",
      alignJustify: "Align Justify",
      undo: "Undo",
      redo: "Redo",
      indent: "Increase Indent",
      outdent: "Decrease Indent",
      formatOL: "Ordered List",
      formatUL: "Unordered List",
      fullscreen: "Fullscreen",
      html: "HTML View",
      fontSize: "Font Size",
      fontFamily: "Font Family",
      textColor: "Text Color",
    }),
    []
  );

  const getOptionKeys = useCallback(
    (humanReadableArray) => {
      const reverseMap = Object.fromEntries(
        Object.entries(toolbarButtonsMap).map(([key, value]) => [value, key])
      );

      return humanReadableArray.map((label) => reverseMap[label]);
    },
    [toolbarButtonsMap]
  );

  const imageConfigs = useMemo(
    () => ({
      imageUpload: config.ToolbarButtons?.includes("Insert Image"),
      imageInsertButtons: config.ImageInsertButtons,
      imageAllowedTypes: config.ImageAllowedTypes,
      imageMaxSize: config.ImageMaxSize,
      imageDefaultWidth: config.ImageDefaultWidth,
    }),
    [config]
  );

  const toolbarButtons = useMemo(
    () => (config.ToolbarButtons ? getOptionKeys(config.ToolbarButtons) : []),
    [config.ToolbarButtons, getOptionKeys]
  );

  const toolbarConfigs = useMemo(
    () => ({
      toolbarInline: config.ToolbarInline,
      toolbarVisibleWithoutSelection: false,
      // toolbarSticky: config.ToolbarSticky,
      toolbarButtons: toolbarButtons,
      // toolbarButtonsXS: config.ToolbarButtonsXS,
      // toolbarButtonsSM: config.ToolbarButtonsSM,
      // toolbarButtonsMD: config.ToolbarButtonsMD,
    }),
    [config, toolbarButtons]
  );

  const videoConfigs = useMemo(
    () => ({
      videoUpload: config.ToolbarButtons?.includes("Insert Video"),
      videoAllowedTypes: config.VideoAllowedTypes,
      videoDefaultWidth: config.VideoDefaultWidth,
      videoInsertButtons: config.VideoInsertButtons,
      videoDefaultDisplay: config.VideoDefaultDisplay,
    }),
    [config]
  );

  const fileConfigs = useMemo(
    () => ({
      fileUpload: config.ToolbarButtons?.includes("Insert File"),
      fileAllowedTypes: config.FileAllowedTypes,
      fileMaxSize: config.FileMaxSize,
    }),
    [config]
  );

  const linkConfigs = useMemo(
    () => ({
      // linkAlwaysNoFollow: config.LinkAlwaysNoFollow,
      linkInsertButtons: config.LinkInsertButtons,
      linkEditButtons: config.LinkEditButtons,
    }),
    [config]
  );

  const editorConfigs = useMemo(
    () => ({
      charCounterMax: config.CharCounterMax,
      charCounterCount: config.CharCounterCount ?? false,
      placeholderText: config.PlaceholderText,
      quickInsertButtons: config.QuickInsertButtons,
      quickInsertEnabled: config.QuickInsertEnabled,
      quickInsertTags: config.QuickInsertTags,
      // pastePlain: config.PastePlain,
      // tabSpaces: config.TabSpaces,
      height: config.Height,
      heightMin: config.HeightMin,
      heightMax: config.HeightMax,
      key: import.meta.env.VITE_FROALA_KEY,
      attribution: false,
      imageUploadRemoteUrls: false,
      pluginsEnabled: [
        "image",
        "video",
        "file",
        "link",
        "quickInsert",
        "charCounter",
        "draggable",
        "embedly",
        "align",
        "colors",
        "fontSize",
        "fontFamily",
        "lists",
        "fullscreen",
      ],
    }),
    [config]
  );

  const createLinkMap = (links) => {
    const linkMap = new Map<string, string>();
    links.forEach((link) => {
      linkMap.set(link.href, link);
    });
    return linkMap;
  };

  const getNewLinks = useCallback(
    (links) => {
      const newLinks = links.filter((link) => !addedLinks.value.has(link.href));
      return newLinks;
    },
    [addedLinks.value]
  );

  const getFileType = (file: File) => {
    const type = file.type.split("/")[0];
    return type !== UploadType.Image && type !== UploadType.Video
      ? UploadType.File
      : type;
  };
  const handleBeforeUpload = useCallback(
    async (files) => {
      const uploadType = getFileType(files[0]);

      if (uploadType === UploadType.File)
        addedLinks.value = createLinkMap(Array.from(editor.value.doc.links));
      const file = files[0];
      if (file) {
        await saveOnAssets(file, uploadType);
        editor.value.events.trigger(`${uploadType}.uploaded`, null, true);
      }
    },
    [addedLinks, saveOnAssets]
  );

  const handleFileUploaded = useCallback(() => {
    const links = Array.from(editor.value.doc.links);
    const newLinks = getNewLinks(links);
    const fileUploaded = newLinks[0];
    const assetUrl = getRepositoryAssetsBaseUrl();
    if (!fileUploaded.href.includes(assetUrl)) {
      formatAssetUrl(fileUploaded, UploadType.File);
    }
  }, [editor.value, formatAssetUrl, getNewLinks]);

  const handleVideoUploaded = useCallback(() => {
    const filesList = editor.value[UploadType.Video].get();
    const fileUploaded = filesList[0].children[0];
    const assetUrl = getRepositoryAssetsBaseUrl();
    if (!fileUploaded.src.includes(assetUrl)) {
      formatAssetUrl(fileUploaded, UploadType.Video);
    }
  }, [editor.value, formatAssetUrl]);

  const handleImageUploaded = useCallback(() => {
    const filesList = editor.value[UploadType.Image].get();
    const fileUploaded = filesList[0];
    const assetUrl = getRepositoryAssetsBaseUrl();
    if (!fileUploaded.src.includes(assetUrl)) {
      formatAssetUrl(fileUploaded, UploadType.Image);
    }
  }, [editor.value, formatAssetUrl]);

  const handleUploaded = useCallback(
    (uploadType: UploadType) => {
      if (uploadType === UploadType.File) handleFileUploaded();
      if (uploadType === UploadType.Video) handleVideoUploaded();
      if (uploadType === UploadType.Image) handleImageUploaded();
    },
    [handleFileUploaded, handleImageUploaded, handleVideoUploaded]
  );

  type DropkiqUI = { menuIsOpen: () => boolean };
  const dropkiqUI = useSignal<DropkiqUI>({} as DropkiqUI);

  // callback to retrieve the Dropkiq UI, to be able to close the menu when the user presses enter
  const setDropkiqUI = (ui) => {
    dropkiqUI.value = ui;
  };

  function initialize() {
    editorRef.value = this.el;

    this.events.on(
      "keydown",
      function (e) {
        if (
          e.which == FroalaEditor.KEYCODE.ENTER &&
          dropkiqUI.value.menuIsOpen()
        ) {
          return false;
        }
      },
      true
    );
  }
  const editorRef = useSignal<HTMLDivElement | null>(null);
  const eventConfigs = useMemo(
    () => ({
      events: {
        "image.beforeUpload": handleBeforeUpload,
        "image.uploaded": () => handleUploaded(UploadType.Image),
        "file.beforeUpload": handleBeforeUpload,
        "file.uploaded": () => handleUploaded(UploadType.File),
        "video.beforeUpload": handleBeforeUpload,
        "video.uploaded": () => handleUploaded(UploadType.Video),
        contentChanged: function () {
          local.Value.value = editor.value.html.get();
        },
        initialized: initialize,
      },
    }),
    [handleBeforeUpload, handleUploaded, initialize, local.Value]
  );

  // Key to force re-render
  const [editorKey, setEditorKey] = useState(0);
  // Watch for config changes and trigger re-render by updating the key
  useEffect(() => {
    setEditorKey((prevKey) => prevKey + 1); // Change the key to force re-render
  }, [config]); // Add other dependencies if needed

  const getDefinedValues = (values) => {
    const definedValues = {};
    Object.keys(values).forEach((key) => {
      if (Array.isArray(values[key]) && values[key].length > 0) {
        definedValues[key] = values[key];
      } else if (!Array.isArray(values[key]) && values[key] !== undefined) {
        definedValues[key] = values[key];
      }
    });
    return definedValues;
  };
  const froalaConfig = useMemo(
    () => ({
      ...getDefinedValues(imageConfigs),
      ...getDefinedValues(eventConfigs),
      ...getDefinedValues(toolbarConfigs),
      ...getDefinedValues(videoConfigs),
      ...getDefinedValues(fileConfigs),
      ...getDefinedValues(linkConfigs),
      ...getDefinedValues(editorConfigs),
    }),
    [
      imageConfigs,
      eventConfigs,
      toolbarConfigs,
      videoConfigs,
      fileConfigs,
      linkConfigs,
      editorConfigs,
    ]
  );

  useEffect(() => {
    editor.value = new (FroalaEditor as any)(`#${config.Id}`, {
      onModelChange: handleModelChange,
      model: local.Value.value,
      ...froalaConfig,
      events: {
        ...eventConfigs.events,
      },
    });
  }, [
    context,
    eventConfigs.events,
    config,
    local.Value,
    handleModelChange,
    froalaConfig,
    editor,
  ]);

  return (
    <div
      key={editorKey}
      id={config.Id}
      data-testid={getTestId(config)}
      data-type={config.__typename}
    >
      {editorRef.value && config.ContextAutocomplete && (
        <NGContextAutocomplete
          config={config.ContextAutocomplete}
          context={{
            ...context,
            setDropkiqUI,
            Element: editorRef.value,
          }}
        />
      )}
    </div>
  );
}
