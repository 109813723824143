import { ITabContainerProps } from "../library/NGFieldExtensions";
import { isNil } from "lodash-es";
import { useComputed, useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { getClassName, getStyleObject, getTestId, getsxObject, keyByRec } from "../library/utils";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import NGReviewDialog from "../components/NGReviewDialog/NGReviewDialog";
import { containerWithPaper } from "../generators/GeneratorUtils";
import NGLayoutItem from "../generators/NGLayoutItem";
import useResizable from "../hooks/useResizable";
import { LayoutItem, TabItem } from "../../resolvers-types";

const tag = "NGTabContainer";

export default function NGTabContainer({ config, context }: ITabContainerProps) {
  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      TabClasses: useSignal(config.TabClasses ?? []),
      Resizable: useSignal(config.Resizable ?? false),
      BoxStyle: useSignal(config.BoxStyle ?? {}),
      TabStyle: useSignal(config.TabStyle ?? {}),
      ContentClasses: useSignal(config.ContentClasses ?? []),
      Visible: useSignal(config.Visible ?? true),
      InReviewMode: useSignal(config.InReviewMode || false),
      SelectedTabId: useSignal(
        config.SelectedTabId ?? (config.Items && config.Items.length > 0) ? config.Items[0]?.Id : null
      ),
    },
    context
  );

  const selectedTab = useComputed(() => {
    const index = config.Items?.findIndex((item) => item.Id === local.SelectedTabId.value);
    return index > -1 ? index : 0;
  });

  const handlers = setupHandlers(config, context);

  if (!isNil(config.ReviewDialogOptions)) {
    (config.ReviewDialogOptions as any).ContextId = (config as any).ContextId;
  }

  const { resizable } = useResizable(local.Resizable.value);

  function renderContainer() {
    const handleChange = (e: React.SyntheticEvent, newValue: number) => {
      const { Title, Id } = config.Items[newValue] as TabItem;
      local.SelectedTabId.value = Id;

      if (handlers["onChange"]) {
        handlers["onChange"](e, { Index: newValue, Id, Title });
      }
    };

    return (
      <Box
        sx={getsxObject(local.BoxStyle.value)}
        data-testid={getTestId(config)}
        data-type={config.__typename}
        className={getClassName(local.Classes)}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Tabs
            value={selectedTab.value}
            aria-label={config.AriaLabel ?? "tabs"}
            className={getClassName(local.TabClasses)}
            sx={getsxObject(local.Style.value)}
            TabIndicatorProps={{ sx: config.TabIndicatorStyles }}
            {...handlers}
            onChange={handleChange}
          >
            {config.Items?.filter((x) => x.__typename == "TabItem").map((tab, key) => {
              return (
                <Tab
                  key={key}
                  disabled={false}
                  label={
                    <NGLayoutItem
                      key={key}
                      config={{ __typename: "Label", Id: tab.Id as string, Value: (tab as TabItem).Title as string }}
                      context={context}
                    />
                  }
                  sx={getStyleObject(local.TabStyle.value, { textTransform: "none" })}
                  className={getClassName(local.TabClasses.value)}
                />
              );
            })}
          </Tabs>
          {config.Items?.filter((x) => x.__typename != "TabItem").map((item, key) => (
            <NGLayoutItem key={key} config={item as LayoutItem} context={context} />
          ))}
        </Stack>
        <div className={getClassName(local.ContentClasses)}>
          {config.Items?.map((tab, tabkey) => {
            return tab.Items?.map((item, key) => {
              if (isNil(item)) {
                return <></>;
              }
              // The Box below is necessary to avoid error: "Each child in a list should have a unique "key" prop"
              return (
                <Box key={`${tabkey}-${key}`}>
                  {selectedTab.value === tabkey && <NGLayoutItem config={item} context={context} />}
                </Box>
              );
            });
          })}
        </div>
        {local.InReviewMode.value && (
          <NGReviewDialog config={config.ReviewDialogOptions ?? {}} context={context}></NGReviewDialog>
        )}
      </Box>
    );
  }

  return resizable(containerWithPaper(local, config, renderContainer, context));
}
