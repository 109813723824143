import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";

import { Add, Delete } from "@mui/icons-material";
import { useSignal } from "@preact/signals-react";
import { INGTypedBindingEditorProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { debouncedHandler } from "../../library/utils";
import jsonData from "../../propertiesEditor/editorProperties.json";

const NGTypedBindingEditor = ({ config, context }: INGTypedBindingEditorProps) => {
  const local = setupLocalState(config, { Value: useSignal(config.Value ?? {}) }, context);
  const handlers = setupHandlers(config, context);

  const anchorEl = useSignal<any>(null);
  const addBindingMenuOpen = Boolean(anchorEl.value);

  const bindings = local.Value;

  const bindingFields = (config.Type && jsonData[config.Type])?.fields ?? [];

  const handleValueChange = (e, key: string, value: string) => {
    local.Value.value = { ...bindings.value, [key]: value };
    updateValues(e);
    e.stopPropagation();
  };

  const updateValues = (e?) => {
    if ((handlers as any).onChange) {
      debouncedHandler((handlers as any).onChange, e, local.Value.value);
    }
  };

  const handleDeleteBinding = (e, key: string) => {
    delete bindings.value[key];
    local.Value.value = { ...bindings.value };

    updateValues(e);
  };

  const handleCloseMenu = () => {
    anchorEl.value = null;
  };

  const handleAddBinding = (e, value) => {
    local.Value.value = { ...bindings.value, [value]: "" };
    updateValues();
    handleCloseMenu();
  };

  const values = Object.entries(bindings.value ?? {});

  return (
    <Stack gap="0.1rem" sx={{ width: "100%" }} marginBottom={values.length > 0 ? "10px" : ""}>
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography className="control-label">{config.Title}</Typography>
        <IconButton
          color="inherit"
          size="small"
          onClick={(e) => (anchorEl.value = e.target)}
          sx={{ flex: "none !important", width: "40px", maxWidth: "40px", height: "40px" }}
        >
          <Add />
        </IconButton>
      </Stack>
      {values.length > 0 && (
        <Stack direction="column" gap="0.6rem" alignItems="center">
          {values.map(([key, value], index) => (
            <Stack direction="row" key={index} alignItems="center" width="100%">
              <Stack direction="column" gap="0.2rem" key={index} flex="auto">
                <Typography style={{ alignSelf: "start" }} className="control-label">
                  {key} Binding
                </Typography>
                <Stack direction="row" gap="0.2rem" key={index} alignItems="center" flex="auto">
                  <TextField
                    size="small"
                    placeholder="Value"
                    value={value}
                    onChange={(e) => handleValueChange(e, key, e.target.value)}
                    className="prop-editor-input"
                  />
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={(e) => handleDeleteBinding(e, key)}
                    className="prop-editor-action-button"
                    sx={{ width: "40px", maxWidth: "40px", height: "40px" }}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
      <Menu
        id="add-typed-bindings-menu"
        anchorEl={anchorEl.value}
        open={addBindingMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ marginTop: "10px", marginLeft: "10px" }}
      >
        {bindingFields.map((option, index) => (
          <MenuItem
            key={index}
            onClick={(e) => handleAddBinding(e, option.name)}
            disabled={!!Object.keys(bindings.value).includes(option.name)}
          >
            <ListItemText>{option.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default NGTypedBindingEditor;
