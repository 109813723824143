import { useSignal, useSignalEffect } from "@preact/signals-react";
import { INGIFrameProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupIframe, setupLocalState } from "../../library/dataService";
import "./iframe.css";
import { useRef } from "react";
import { log } from "../../library/logger";
import {
  getStyleObject,
  getTestId,
  getClassName,
  addImpersonationIdentifierToUrl,
  normalizeSessionUrl,
} from "../../library/utils";

const tag = "iframe";

export function NGIFrame({ config, context }: INGIFrameProps) {
  const iframeRef = useRef(null);
  const iframeId = config.Id;
  const divAroundIFrameTestId = `divAroundIFrame_${getTestId(config)}`;

  const local = setupLocalState(
    config,
    {
      Width: useSignal(config.Width || "100%"),
      Height: useSignal(config.Height || "90vh"),
      Source: useSignal(config.Source || ""),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  function handleMessage(message) {
    const msg = message.data;
    if (msg.Source != "ng") return;
    log.debug(tag, "handleMessage", msg, window.location.href);

    if (handlers[msg.Type]) {
      log.debug(tag, "handleMessage", "got handler message: ", msg, window.location.href);
      handlers[msg.Type](msg, msg);
    }
  }

  useSignalEffect(() => {
    log.debug(tag, "useSignalEffect", iframeRef.current?.ContentWindow, window.location.href);

    // init the iframe once
    if (!iframeRef.current?.ContentWindow) {
      setupIframe(config.Id, iframeRef.current);

      window.addEventListener("message", handleMessage);
    }
  });

  const iframeStyle = {
    width: local.Width.value,
    height: local.Height.value,
  };

  const className = getClassName(local.Classes);

  return (
    <>
      <div style={{ textAlign: "center" }} data-testid={divAroundIFrameTestId}>
        <iframe
          data-testid={getTestId(config)}
          id={iframeId}
          ref={iframeRef}
          src={normalizeSessionUrl(local.Source.value)}
          style={getStyleObject(local.Style.value, iframeStyle)}
          className={className ? `${className} iframe` : "iframe"}
        />
      </div>
    </>
  );
}
