import { useSignal } from "@preact/signals-react";
import { setupLocalState } from "../../library/dataService";

import { DropkiqUIFromScope } from "./DropkiqUIFromScope";
import "./dropkiq.css";

import { Buffer } from "buffer";
import { useEffect } from "react";
import { INGContextAutocomplete } from "../../library/NGFieldExtensions";
import { getTestId } from "../../library/utils";
import { DropkiqUI } from "./DropkiqUI";
import { isArray } from "highcharts";

window.Buffer = Buffer;

const DROPKIQ_LICENSE_KEY = import.meta.env.VITE_DROPKIQ_LICENSE_KEY;

export function NGContextAutocomplete({
  config,
  context,
}: INGContextAutocomplete) {
  const local = setupLocalState(
    config,
    {
      Disabled: useSignal(config.Disabled ?? false),
      Model: useSignal(config.Model ?? {}),
    },
    context
  );

  const dropkiqUiRef = useSignal<DropkiqUI | null>(null);

  useEffect(() => {
    if (
      local.Disabled.value &&
      dropkiqUiRef.value &&
      (!local.Model.value || isArray(local.Model.value))
    ) {
      dropkiqUiRef.value.disable();
      return;
    }

    if (
      dropkiqUiRef.value &&
      !dropkiqUiRef.value.isEnabled &&
      !local.Disabled.value
    ) {
      dropkiqUiRef.value.enable();
    } else {
      dropkiqUiRef.value = new DropkiqUIFromScope(
        context.Element,
        local.Model.value || {},
        DROPKIQ_LICENSE_KEY,
        {}
      ).dropkiqUI();
    }
    context.setDropkiqUI && context.setDropkiqUI(dropkiqUiRef.value);
  }, [context, dropkiqUiRef, local.Disabled.value, local.Model.value]);

  return (
    <div
      id={config.Id}
      data-testid={getTestId(config)}
      data-type={config.__typename}
    />
  );
}
