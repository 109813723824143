import { Ref } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { InputAdornment } from "@mui/material";
import { INGListQuickFilterProps, RuntimeContext } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { useSignal } from "@preact/signals-react";
import { isNil } from "lodash-es";
import NGIcon from "../NGIcon/NGIcon";
import { getClassName, getsxObject, getTestId } from "../../library/utils";
import { getGridContext } from "../../library/metadataUtils";

const GridToolbarQuickFilterRoot = styled(TextField)(({ theme }) => ({
  width: "auto",
  marginLeft: "auto",
  paddingBottom: theme.spacing(0.5),
  "& input": {
    marginLeft: theme.spacing(0.5),
  },
  "& .MuiInput-underline:before": {
    "&:hover": {
      borderBottom: `1px solid #000`,
    },
  },
  [`& input[type="search"]::-webkit-search-decoration,
  & input[type="search"]::-webkit-search-cancel-button,
  & input[type="search"]::-webkit-search-results-button,
  & input[type="search"]::-webkit-search-results-decoration`]: {
    /* clears the 'X' icon from Chrome */
    display: "none",
  },
}));

function NGListQuickFilter({ config, context }: INGListQuickFilterProps) {
  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Disabled: useSignal(config.Disabled ?? false),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  const InputProps: any = {};
  if (!isNil(config.Adornment) && !isNil(config.Adornment.Position)) {
    const p = config.Adornment.Position.toLowerCase();

    InputProps[`${p}Adornment`] = (
      <InputAdornment position={p as any}>
        <NGIcon config={{ IconName: "Search" }} context={context} />
      </InputAdornment>
    );
  }

  const { QuickFilterRef } = getGridContext(context);

  return local.Visible.value ? (
    <GridToolbarQuickFilterRoot
      data-testid={getTestId(config)}
      data-type={config.__typename}
      variant="standard"
      inputRef={QuickFilterRef as Ref<unknown>}
      className={getClassName(local.Classes)}
      disabled={local.Disabled.value}
      placeholder={config.PlaceholderText ?? ""}
      type="search"
      InputProps={InputProps}
      sx={getsxObject(local.Style.value)} // , { marginLeft: "auto" }
      onChange={config.onInput}
      {...handlers}
    />
  ) : null;
}

export { NGListQuickFilter };
