import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { NGRouter } from "./NGRouter.tsx";
import "./library/native";
import tracing from "./library/tracing.ts";
import { OidcProvider } from "./library/auth.ts";
import { fixNodeRemoveBug } from "./library/utils.ts";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { themes } from "./theme.ts";

tracing.createAnnonymousId();

const originalFetch = window.fetch;
// Create a wrapper function around the original fetch
window.fetch = async function (url, options = {}) {
  const _url = url.toString();
  if (_url.includes(import.meta.env.VITE_OIDC_HOST || _url.includes("silent-sso"))) {
    return originalFetch(url, options);
  }

  options.headers = options.headers || {};
  if (typeof sessionStorage != "undefined") {
    const isImpersonate = sessionStorage.getItem("impersonateId") !== null;
    if (isImpersonate) {
      options.headers["impersonate-id"] = sessionStorage.getItem("impersonateId");
    }
  }

  return originalFetch(url, options);
};

fixNodeRemoveBug();

const currentTheme = themes["default"];

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <OidcProvider>
      <DndProvider backend={HTML5Backend}>
        <StyledEngineProvider injectFirst>
          {/* <ThemeProvider theme={currentTheme}> */}
          <SnackbarProvider maxSnack={10}>
            {/* <CssBaseline enableColorScheme /> */}
            <RouterProvider router={NGRouter} />
          </SnackbarProvider>
          {/* </ThemeProvider> */}
        </StyledEngineProvider>
      </DndProvider>
    </OidcProvider>
  </StrictMode>
);
