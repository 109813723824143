import { useSignal, useSignalEffect } from '@preact/signals-react'
import { Box, IconButton as IButton, styled } from '@mui/material'
import { setupHandlers, setupLocalState } from '../../library/dataService'
import { INGOverlayProps } from '../../library/NGFieldExtensions'
import { useRef } from 'react'
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { DragIndicator } from '@mui/icons-material'
import { getsxObject, getTestId } from '../../library/utils'
import NGLayoutItem from '../../generators/NGLayoutItem'
import { Overlay } from '../../../resolvers-types'

const IconButton = styled(IButton)`
  &:hover {
    background-color: transparent;
  }
`

const getInitialPosition = (config: Overlay) => {
  const storagedPosition: { top?: number, left?: number, right?: number, bottom?: number } = config.Id ? JSON.parse(localStorage.getItem(config.Id) || "null") : null
  if (!storagedPosition) return config.Position ?? { top: 0, left: 0 };

  // if storagedPosition.top is bigger than the window height, set it auto and bottom to  10
  if ((storagedPosition?.top ?? 0) > window.innerHeight) {
    delete storagedPosition.top;
    storagedPosition.bottom = 10;
  }
  // if storagedPosition.left is bigger than the window width, set it auto and right to  10
  if ((storagedPosition?.left ?? 0) > window.innerWidth) {
    delete storagedPosition.left;
    storagedPosition.right = 10;
  }
  return storagedPosition;
}

export default function NGOverlay({ config, context, children }: INGOverlayProps) {
  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? false), // true onyl if set
      Style: useSignal(config.Style ?? {}),
      Position: useSignal(getInitialPosition(config)),
      Classes: useSignal(config.Classes ?? []),
    },
    context,
  );

  const handlers = setupHandlers(config, context);
  const ref = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLButtonElement>(null);

  useSignalEffect(() => {
    const element = ref.current;
    const dragHandle = dragHandleRef.current;
    if (!element || !dragHandle) return;

    const stopDragging = draggable({
      element,
      dragHandle: dragHandle,
      getInitialData: () => config,
      canDrag: () => !!config.Draggable,
      onDrop({ location, source }) {
        const { current: { input } } = location;
        const { dragHandle } = source;
        // Adjust the position based on the drag handle
        const handleTopAdjustment = (dragHandle ? (dragHandle as HTMLElement).offsetHeight / 2 : 0);
        const handleLeftAdjustment = (dragHandle ? (dragHandle as HTMLElement).offsetLeft + (dragHandle as HTMLElement).offsetWidth / 2 : 0);
        local.Position.value = {
          top: input.clientY - handleTopAdjustment, // Adjust the Y position
          left: input.clientX - handleLeftAdjustment, // Adjust the X position
        }
        // Call the onDrop handler if it exists
        if (handlers["onDrop"]) handlers["onDrop"]("drop", { Id: config.Id, Position: local.Position.value });
      },
    });

    return () => {
      stopDragging(); // Clean up on unmount
    };
  });

  return local.Visible.value ? (
    <Box
      position="fixed"
      zIndex={10000}
      ref={ref}
      top={local.Position.value.top}
      left={local.Position.value.left}
      right={local.Position.value.right ?? undefined}
      bottom={local.Position.value.bottom ?? undefined}
      data-testid={getTestId(config)}
      sx={getsxObject(local.Style.value, { display: "flex", flexDirection: "row", padding: 0 })}
      className={local.Classes.value.join(" ")}
    >
      {config.Items?.map((item) => (
        <NGLayoutItem key={(item as any).Id} config={item} context={context} />
      ))}
      {children}
      {config.Draggable && (
        <IconButton size="small" ref={dragHandleRef} id="drag-button">
          <DragIndicator />
        </IconButton>
      )}
    </Box>
  ) : null
}
