import {
  CheckboxSelectionCallbackParams,
  ColDef,
  ICellRendererParams,
  HeaderCheckboxSelectionCallbackParams,
  ExcelStyle,
  GetRowIdParams,
  GridApi,
  ISetFilterParams,
  IDateFilterParams,
  ProcessCellForExportParams,
  ExcelDataType,
  ExcelNumberFormat,
} from "ag-grid-charts-enterprise";
import { LayoutItem, List, ListColumn, Maybe, SpreadsheetModeOptions } from "../../../resolvers-types";
import { isNil } from "lodash-es";
import { formatBoolean, formatDate, formatNumber, generateUID, hasTimeProperties } from "../../library/utils";
import { GetFormatsFromSite, getFormData, getScope, getState } from "../../library/dataService";
import { getExprValue } from "../../library/interpreter";
import { RuntimeContext } from "../../library/NGFieldExtensions";
import { excelDateFormat, excelNumberFormat } from "../../library/excelFormatter";

export type IAnyObject = { [key: string]: string };

export interface CustomColDef extends ColDef {
  listCol?: ListColumn;
  autoSize?: boolean;
}

const defaultRowHeight = 42;
const defaultRowsPerPage = 20;
const defaultPageSelector = [10, 20, 50, 100, 500, 1000];

function addRowPerPageToSelector(rowsPerPage: number | string): number[] {
  const nRowsPerPage = Number(rowsPerPage);
  const selector = [...defaultPageSelector];
  if (selector.indexOf(nRowsPerPage) === -1) {
    selector.push(nRowsPerPage);
    selector.sort((a, b) => a - b);
  }
  return selector;
}

function checkboxSelection(params: CheckboxSelectionCallbackParams | ICellRendererParams, gridApi?: GridApi) {
  const columns = gridApi?.getColumnDefs() as CustomColDef[];
  const hasVisibleColumns = (columns ?? []).filter((col) => !col.hide).length > 0;
  if (!params.node.group) {
    return !params.node.parent?.groupData && hasVisibleColumns;
  }
  return false;
}

function headerCheckboxSelection(params: HeaderCheckboxSelectionCallbackParams) {
  return params.api.getRowGroupColumns().length === 0;
}

function getFormatDataType(format, value, dataType) {
  if (isNil(format)) return value;
  if (!dataType) return value;
  if (dataType === "number" && !isNil(format.NumberFormat)) return formatNumber(value, format.NumberFormat);

  if (dataType === "date" && !isNil(format.DateFormat)) {
    if (hasTimeProperties(format.DateFormat))
      return "Error - Date format with time is not supported.  Change column type to datetime";

    return formatDate(value, format.DateFormat);
  }

  if (dataType === "boolean") {
    return formatBoolean(value, format?.BooleanFormat);
  }

  if (dataType === "datetime" && !isNil(format.DateFormat)) return formatDate(value, format.DateFormat);

  //hasTimeProperties
  return value;
}

function resolveClasses(classes?: Maybe<string[]>, FormatName?: Maybe<string>) {
  return (classes || []).concat(FormatName ? [FormatName] : []) ?? [];
}

function getExportDataDefaults(api: GridApi) {
  return {
    processCellCallback(params: ProcessCellForExportParams) {
      const dateType = params.column.getColDef().cellDataType;
      if (dateType === "date") {
        // for type date get only year/month/day
        return params.value ? new Date(params.value).toISOString().split("T")[0] : params.value;
      }
      if (dateType === "datetime") {
        // for type datetime get year/month/day/hour/minute/second
        return params.value ? new Date(params.value).toISOString().split(".")[0] : params.value;
      }
      if (dateType === "number") {
        return params.value;
      }
      return params.formatValue(params.value);
    },
    // get column keys to avoid exporting hidden columns or checkboxes
    columnKeys: api?.getColumns()?.filter((col) => col.isVisible()) ?? [],
  };
}

function getDataTypeFilter(dataType) {
  if (!dataType) return "agTextColumnFilter";
  if (dataType === "number") return "agNumberColumnFilter";
  if (dataType === "boolean") return "agSetColumnFilter";
  if (dataType === "date" || dataType === "datetime") return "agDateColumnFilter";
}

function getFilterParams(dataType) {
  if (dataType === "date") {
    return {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        if (!cellValue) return 0;

        const [month, day, year] = cellValue.split("/").map(Number);
        const cellDate = new Date(year, month - 1, day);

        if (cellDate < filterLocalDateAtMidnight) return -1;
        if (cellDate > filterLocalDateAtMidnight) return 1;
        return 0;
      },
    } as IDateFilterParams;
  }

  if (dataType === "boolean") {
    return {
      values: ["true", "false"],
      valueFormatter(params) {
        return params.value;
      },
    } as ISetFilterParams;
  }
  return undefined;
}

const defaultGroupColumn = (config: List): ColDef => ({
  headerName: "Group",
  minWidth: 150,
  initialWidth: 250,
  field: "name",
  cellClass: config.GroupingClasses ?? undefined,
  headerClass: config?.GroupingHeaderClasses ?? undefined,
});

function getRows(rows: { [key: string]: string }[] | undefined | null) {
  return (
    rows?.map((row) => ({
      ...row,
      Id: row?.Id ?? row?.id ?? generateUID(),
    })) ?? []
  );
}
const defaultExportParams = {
  headerRowHeight: 40,
  rowHeight: 30,
  fontSize: 14,
  addImageToCell: () => null,
};

const getRowId = ({ data }: GetRowIdParams) => {
  return data?.Id ?? data?.id ?? generateUID();
};

function numberToLetters(n: number): string {
  let result = "";
  while (n > 0) {
    n--; // Decrement n first to convert from 1-based index to 0-based.
    const remainder = n % 26;
    const letter = String.fromCharCode(remainder + 65); // Convert to ASCII (65 is "A")
    result = letter + result;
    n = Math.floor(n / 26);
  }
  return result;
}

function addBlankRows(numberOfRows: number): IAnyObject[] {
  const newRows: IAnyObject[] = [];

  for (let i = 1; i <= numberOfRows; i++) {
    const newRow = {
      Id: generateUID(),
    };
    newRows.push(newRow);
  }

  return newRows;
}

function addColumns(config: List, idStart: number, skipAddingId: boolean, numberOfColumns: number): ListColumn[] {
  // First column is always the ID column
  const columns: ListColumn[] = [];
  const options: SpreadsheetModeOptions = config?.DoNotEnforceIdColumn
    ? {
        DoNotEnforceIdColumn: config?.DoNotEnforceIdColumn,
      }
    : {};

  if (options.DoNotEnforceIdColumn !== true && !skipAddingId) {
    const idColumm = {
      __typename: "ListColumn",
      Id: `${idStart++}`,
      HeaderName: "",
      Classes: ["MuiDataGrid-columnHeader"],
      Name: "Id",
      MinWidth: 40,
      IsPrimaryKey: true,
    } as ListColumn;
    columns.push(idColumm);
  }

  for (let i = 0; i < numberOfColumns; i++) {
    const column = {
      __typename: "ListColumn",
      Id: (i + idStart).toString(),
      HeaderName: numberToLetters(i + idStart),
      Name: numberToLetters(i + idStart),
      Editable: true,
    } as ListColumn;

    columns.push(column);
  }

  return columns;
}

const excelStyles = (): ExcelStyle[] => {
  const formats = GetFormatsFromSite() as {
    [key: string]: { NumberFormat?: Intl.NumberFormatOptions; DateFormat?: Intl.DateTimeFormatOptions };
  };
  const formatsArray = Object.entries(formats).map(([key, value]) => ({
    id: key,
    dataType: value.DateFormat
      ? ("DateTime" as ExcelDataType)
      : value.NumberFormat
      ? ("Number" as ExcelDataType)
      : ("String" as ExcelDataType),
    numberFormat: {
      format: value.DateFormat
        ? excelDateFormat(value.DateFormat)
        : value.NumberFormat
        ? excelNumberFormat(value.NumberFormat)
        : "",
    } as ExcelNumberFormat,
  }));
  return [
    {
      id: "v-align",
      alignment: {
        vertical: "Center",
      },
    },
    {
      id: "alphabet",
      alignment: {
        vertical: "Center",
      },
    },
    {
      id: "good-score",
      alignment: {
        horizontal: "Center",
        vertical: "Center",
      },
      interior: {
        color: "#C6EFCE",
        pattern: "Solid",
      },
      numberFormat: {
        format: "[$$-409]#,##0",
      },
    },
    {
      id: "bad-score",
      alignment: {
        horizontal: "Center",
        vertical: "Center",
      },
      interior: {
        color: "#FFC7CE",
        pattern: "Solid",
      },
      numberFormat: {
        format: "[$$-409]#,##0",
      },
    },
    {
      id: "header",
      font: {
        color: "#44546A",
        size: 16,
      },
      interior: {
        color: "#F2F2F2",
        pattern: "Solid",
      },
      alignment: {
        horizontal: "Center",
        vertical: "Center",
      },
      borders: {
        borderTop: {
          lineStyle: "Continuous",
          weight: 0,
          color: "#8EA9DB",
        },
        borderRight: {
          lineStyle: "Continuous",
          weight: 0,
          color: "#8EA9DB",
        },
        borderBottom: {
          lineStyle: "Continuous",
          weight: 0,
          color: "#8EA9DB",
        },
        borderLeft: {
          lineStyle: "Continuous",
          weight: 0,
          color: "#8EA9DB",
        },
      },
    },
    {
      id: "currency-cell",
      alignment: {
        horizontal: "Center",
        vertical: "Center",
      },
      numberFormat: {
        format: "[$$-409]#,##0",
      },
    },
    {
      id: "boolean-type",
      dataType: "Boolean",
      alignment: {
        vertical: "Center",
      },
    },
    {
      id: "country-cell",
      alignment: {
        indent: 4,
      },
    },
    ...formatsArray,
  ];
};

function fromColDefsToConfig(colDefs: CustomColDef[]) {
  return (
    colDefs?.map((col) => {
      const o = {} as ListColumn;
      for (const [k, v] of Object.entries(col)) {
        if (k === "listCol" || k === "autoSize") continue;
        o[k.charAt(0).toUpperCase() + k.slice(1)] = v;
        // workarounds
        if (k === "hide") o["Visible"] = !v;
      }
      return {
        ...col.listCol,
        ...o,
      };
    }) ?? []
  );
}

const getBindings = ({
  params,
  context,
  config,
}: {
  params?: any;
  context: RuntimeContext;
  config?: Maybe<LayoutItem>;
}) => {
  const { state, form, parentState } = getState(context);
  const scope = getScope(context, config, state, form, {}, parentState);
  scope["Row"] = params?.data;
  const configClone = { ...config, Bindings: {}, getData: () => params?.data };
  for (const [k, v] of Object.entries(config?.["Bindings"] || { Value: `Row.${params?.colDef?.field}` })) {
    let srcValue: unknown = null;
    if (v == "Form") {
      srcValue = getFormData(scope.Form);
    } else if (v) {
      srcValue = getExprValue(v as string, scope, null);
    }
    configClone[k] = srcValue;
  }
  return configClone;
};

function removePageElements(testId: string, elementsSelectors: string[]) {
  const listContainer = document.querySelector(`[data-testid='${testId}']`);
  if (!listContainer) return;
  elementsSelectors.forEach((selector) => {
    const element = listContainer.querySelector(selector);
    element?.remove();
  });
}

function getCustomFilters(cols: CustomColDef[]) {
  const customFilters = {};
  // get array columns (support only for string[])
  const f = cols.filter((c) => c.cellDataType === "array");
  f.forEach(({ field }) => {
    if (field)
      customFilters[field] = (colName, col, isCaseSensitive, provider) => {
        // create custom filter for array columns
        return `${colName}/any(${colName}: ${provider.odataOperator[col.type](colName, `'${col.filter}'`, true)})`;
      };
  });

  return customFilters;
}

export {
  getCustomFilters,
  defaultRowHeight,
  defaultRowsPerPage,
  headerCheckboxSelection,
  defaultGroupColumn,
  checkboxSelection,
  getFormatDataType,
  getDataTypeFilter,
  excelStyles,
  getRows,
  defaultExportParams,
  getRowId,
  addBlankRows,
  addColumns,
  numberToLetters,
  fromColDefsToConfig,
  getBindings,
  addRowPerPageToSelector,
  defaultPageSelector,
  removePageElements,
  getFilterParams,
  resolveClasses,
  getExportDataDefaults,
};
