import MenuItem from "@mui/material/MenuItem";
import { INGFeatureProps } from "../../library/NGFieldExtensions";
import { NavLink } from "react-router-dom";
import { getCustomLabel, getTestId, getURLForFeature } from "../../library/utils";
import { setupHandlers } from "../../library/dataService";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

const MenuItemWithSubMenuAccordion = ({ config, key, handlers, handleClose }) => {
  // return config.Features.length === 0 ? (

  // ) : (

  // );
  return (
    <>
      <MenuItem id={key}>
        {config.Features.length === 0 ? (
          key
        ) : (
          <Accordion
            elevation={0}
            disableGutters
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <AccordionSummary
              sx={{
                padding: 0,
                minHeight: 0,
              }}
              classes={{
                content: "custom-accordion",
              }}
            >
              <span>{config.Label}</span>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: 0,
                margin: 0,
                marginTop: ".5rem",
              }}
            >
              {config.Features.map((feature, i) =>
                handlers["onClick"] ? (
                  <MenuItem
                    data-testid={getTestId(feature)}
                    color="inherit"
                    key={feature.Id}
                    onClick={(e) => {
                      console.log("onClick", e);
                      if (handlers["onClick"]) {
                        handlers["onClick"]();
                      }
                      handleClose();
                    }}
                  >
                    {feature.Label}
                  </MenuItem>
                ) : (
                  <MenuItem
                    data-testid={getTestId(feature)}
                    color="inherit"
                    key={feature.Id}
                    component={NavLink}
                    to={getURLForFeature(feature)}
                    onClick={handleClose}
                  >
                    {feature.Label}
                  </MenuItem>
                )
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </MenuItem>
    </>
  );
};

export default function NGFeature({ config, context, handleClose }: INGFeatureProps) {
  const handlers = setupHandlers(config, context);
  // if (config.Features && config.Features.length) {
  //   return (
  //     <MenuItemWithSubMenuAccordion key={config.Id} config={config} handlers={handlers} handleClose={handleClose} />
  //   )
  // }

  return handlers["onClick"] || !config.AllowNavigation ? (
    <MenuItem
      data-testid={getTestId(config)}
      color="inherit"
      key={config.Id}
      onClick={(e) => {
        console.log("onClick", e);
        if (handlers["onClick"] && config.AllowNavigation) {
          handlers["onClick"]();
        }
        handleClose();
      }}
    >
      {config.Label}
    </MenuItem>
  ) : (
    <MenuItem
      data-testid={getTestId(config)}
      color="inherit"
      key={config.Id}
      component={NavLink}
      to={getURLForFeature(config)}
      onClick={handleClose}
    >
      {getCustomLabel(config.Label)}
    </MenuItem>
  );
}
