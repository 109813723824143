import { createReactOidc } from "oidc-spa/react";
import { z } from "zod";
import {
  clearCookiesForImpersonationSessions,
  clearLocalStorageForImpersonationSessions,
  delay,
  getApiUrl,
  getTenant,
  getUser,
  isNullOrEmpty,
  parseAuthCookies,
  setCookie,
} from "./utils";
import { LoaderFunctionArgs } from "react-router-dom";
import { decodeJwt } from "oidc-spa/tools/decodeJwt";
import { log } from "./logger";
import { client } from "./nats-client";
import { Result } from "../models/result";
import { debounce, isString, merge } from "lodash-es";
import { isNative } from "./native";
import tracing from "./tracing";

const tag = "auth";

export const {
  OidcProvider,
  /**
   * Note: If you have multiple OidcProvider in your app
   * you do not need to use the useClient hook that that corresponds
   * to the above OidcProvider.
   */
  useOidc,
  /**
   * This is useful to use the oidc API outside of React.
   */
  getOidc,
} = createReactOidc({
  // If you don't have the parameters right away, it's the case for example
  // if you get the oidc parameters from an API you can pass a promise that
  // resolves to the parameters. `createReactOidc(prParams)`.
  // You can also pass an async function that returns the parameters.
  // `createReactOidc(async () => params)`. It will be called when the <OidcProvider />
  // is first mounted or when getOidc() is called.

  // __unsafe_ssoSessionIdleSeconds: 1 * 10,
  issuerUri: `${import.meta.env.VITE_OIDC_HOST}/realms/${getTenant()}`,
  clientId: import.meta.env.VITE_OIDC_CLIENT,
  publicUrl: import.meta.env.BASE_URL,
  /**
   * This parameter is optional.
   *
   * It allows you to validate the shape of the idToken so that you
   * can trust that oidcTokens.decodedIdToken is of the expected shape
   * when the user is logged in.
   * What is actually inside the idToken is defined by the OIDC server
   * you are using.
   * The usage of zod here is just an example, you can use any other schema
   * validation library or write your own validation function.
   *
   * Note that zod will strip out all the fields that are not defined in the
   * schema, so to know exactly what is inside the idToken you can do:
   * decodedIdTokenSchema: {
   *   parse: (decodedIdToken)=> {
   *     console.log(decodedIdToken);
   *     return z.object({
   *       sub: z.string(),
   *       preferred_username: z.string()
   *     }).parse(decodedIdToken);
   *   }
   * }
   *
   * If you want to specify the type of the decodedIdToken but do not care
   * about validating the shape of the decoded idToken at runtime you can
   * call `createUseOidc<DecodedIdToken>()` without passing any parameter.
   *
   * Note however that in most webapp you do not need to look into the JWT
   * of the idToken on the frontend side, you usually obtain the user info
   * by querying a GET /user endpoint with a authorization header
   * like `Bearer <accessToken>`.
   */
  /*
    decodedIdTokenSchema: {
        parse: (decodedIdToken) => {

            type DecodedIdToken = {
                sub: string;
                preferred_username: string
            };

            console.log(decodedIdToken);

            return decodedIdToken as DecodedIdToken;
        }
    },
    */
  decodedIdTokenSchema: z.object({
    sub: z.string(),
    preferred_username: z.string(),
  }),
  //autoLogoutParams: { redirectTo: "current page" } // Default
  //autoLogoutParams: { redirectTo: "home" }
  //autoLogoutParams: { redirectTo: "specific url", url: "/a-page" }

  // This parameter is optional.
  // It allows you to pass extra query params before redirecting to the OIDC server.
  extraQueryParams: () => ({
    // ui_locales: "en", // Gere you would dynamically get the current language at the time of redirecting to the OIDC server
  }),
  // Remove this in your repo
  doEnableDebugLogs: true,
});

let tokenChangeUnsubscribe = () => {};

async function performPostLogin(auth, tokens, token) {
  const cookies = parseAuthCookies();
  if (cookies["Api.Token"] == tokens.accessToken) {
    console.log("performPostLogin: tokens are same skipping");
    return;
  }

  const start = performance.now();
  console.log("performPostLogin", tokens);
  clearCookiesForImpersonationSessions();
  clearLocalStorageForImpersonationSessions();

  const resp = await fetch(`${getApiUrl()}/auth/post-login`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Tenant: getTenant(),
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  });
  if (!resp.ok) {
    console.error("failed to post login", resp);
    return auth;
  }
  const result = await resp.json();

  const exp = new Date((token.exp ?? new Date().getTime() + 60 * 60) * 1000).toUTCString();
  document.cookie = `Api.Token=${tokens.accessToken}; path=/; expires=${exp}`;
  document.cookie = `Api.NatsJwt=${result.NatsJwt}; path=/; expires=${exp}`;
  document.cookie = `Api.NatsSeed=${result.NatsSeed}; path=/; expires=${exp}`;

  // if impersonating refresh token
  const impersonateId = sessionStorage.getItem("impersonateId") || "";
  if (impersonateId) {
    const refreshToken = localStorage.getItem(`Api.Imp.${impersonateId}.RefreshToken`);
    const userId = localStorage.getItem(`Api.Imp.${impersonateId}.UserId`);
    if (refreshToken) {
      const refreshResp = await fetch(`${getApiUrl()}/auth/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Tenant: getTenant(),
          Authorization: `Bearer ${tokens.accessToken}`,
        },
        body: JSON.stringify({ RefreshToken: refreshToken, UserId: userId }),
      });
      if (refreshResp.ok) {
        const refreshResult = await refreshResp.json();
        localStorage.setItem(`Api.Imp.${impersonateId}.RefreshToken`, refreshResult.RefreshToken);
        localStorage.setItem(`Api.Imp.${impersonateId}.NatsJwt`, refreshResult.NatsJwt);
        localStorage.setItem(`Api.Imp.${impersonateId}.NatsSeed`, refreshResult.NatsSeed);
        localStorage.setItem(`Api.Imp.${impersonateId}.RefreshToken`, refreshResult.RefreshToken);
        localStorage.setItem(`Api.Imp.${impersonateId}.UserId`, refreshResult.UserId);
        setCookie(`Api.Imp.${impersonateId}.Token`, refreshResult.Token, 1);
      }
    }
  }

  const end = performance.now();
  console.log(`performPostLogin took, ${end - start}ms`);
  await delay(100);
}

export async function setupAuth({ request }: LoaderFunctionArgs, global, setupOidc = false) {
  const cookies = parseAuthCookies();
  if (isNative()) {
    const token = cookies["Api.Token"];
    if (!isNullOrEmpty(token)) {
      await setupUser(decodeJwt(token), global);
    }
    return {
      isUserLoggedIn: !isNullOrEmpty(token),
    };
  }

  const auth = await getOidc();

  if (auth.initializationError) {
    console.error("OIDC init error", auth.initializationError);
    return auth;
  }

  if (auth.isUserLoggedIn) {
    if (setupOidc) {
      if (tokenChangeUnsubscribe) {
        tokenChangeUnsubscribe();
      }
      const { unsubscribe } = auth.subscribeToTokensChange(async () => {
        const tokens = auth.getTokens();
        const token = decodeJwt(tokens.accessToken);
        await performPostLogin(auth, tokens, token);
      });
      tokenChangeUnsubscribe = unsubscribe;

      const tokens = auth.getTokens();
      const token = decodeJwt(tokens.accessToken);
      if (auth.authMethod === "back from auth server" || !cookies["Api.Token"]) {
        tracing.createSessionId();
        await performPostLogin(auth, tokens, token);
      }

      if (!global["User"]?.value) {
        const cookies = parseAuthCookies();
        await setupUser(cookies["Api.Token"], global);
      }
    }

    return auth;
  }

  await auth.login({
    // The loader function is invoked by react-router before the browser URL is updated to the target protected route URL.
    // Therefore, we need to specify where the user should be redirected after the login process completes.
    redirectUrl: request.url,

    // Explanation:
    // The 'doesCurrentHrefRequiresAuth' parameter informs oidc-spa whether it is acceptable to redirect the user to the current URL
    // if the user abandons the authentication process. This is crucial to prevent the user from being immediately redirected
    // back to the login page when pressing the back button from the login pages.
    // If the user navigated directly to the protected route (e.g., by clicking a link to your application from an external site),
    // then the current URL requires authentication.
    // Conversely, if the user navigated from an unprotected route within your application to the protected route,
    // then the current URL does not require authentication.
    doesCurrentHrefRequiresAuth: window.location.href === request.url,
  });

  // Never here, the login method redirects the user to the identity provider.
}

async function setupUser(token, global: any) {
  // const token = jwtDecode(cookies["Api.Token"]) as ApiToken;
  // global["User"] = signal(getUser(token));
  const user = getUser(isString(token) ? decodeJwt(token) : token);
  console.log("~~User: got user", user);

  global["User"].value = user;

  const profile = await getUserProfile(user.Id);
  console.log("~~User: got profile", profile);

  const fullUser = merge(user, profile);
  console.log("~~User: updating user with profile", global["User"], fullUser);

  global["User"].value = fullUser;
  return token;
}

async function getUserProfile(id: string) {
  log.info(tag, "Service request", "userprofile.get");
  try {
    const resp = (await client.request("userprofile.get", { Id: id })) as Result<any>;
    if (!resp?.success) {
      log.error(tag, "Service userprofile.get failed", resp?.reasons);
      return false;
    }
    return resp?.data;
  } catch (e) {
    log.error(tag, "Service userprofile.get failed", e);
    return {};
  }
}
