import { Label } from "../../../resolvers-types";
import { INGLabelProps } from "../../library/NGFieldExtensions";
import { Chip, Tooltip } from "@mui/material";
import { useSignal, signal, useSignalEffect, batch } from "@preact/signals-react";
import { GetFormatFromSite, getState, setupHandlers, setupLocalState } from "../../library/dataService";
import Typography from "@mui/material/Typography";
import { isNil, set, isObject, isArray, isString } from "lodash-es";
import {
  formatDate,
  formatNumber,
  getTestId,
  getsxObject,
  isNullOrEmpty,
  getClassName,
  cloneDeepWithoutSelectedFields,
  getCustomLabel,
} from "../../library/utils";
import { log } from "../../library/logger";
import { getExprValue } from "../../library/interpreter";
import NGIcon from "../NGIcon/NGIcon";

const tag = "NGLabel";

function hasConditionalFormat(format: any) {
  // TODO VS: properly type format
  return !isNil(format.ConditionalFormatBindings) && !isNil(format.ConditionalFormats);
}

function enrichConfig(config: any): any {
  if (isNil(config.FormatName)) return config;

  const format = GetFormatFromSite(config.FormatName);

  if (isNil(format)) return config;

  const a = cloneDeepWithoutSelectedFields(format, ["ConditionalFormatBindings", "ConditionalFormats", "Name", "Id"]);

  return { ...config, ...a };
}

export default function NGLabel({ config, context }: INGLabelProps) {
  config = enrichConfig(config);
  const testId = getTestId(config);

  // if (!isNil(config.StartIcon)) config.StartIcon.ContextId = testId;
  // if (!isNil(config.EndIcon)) config.EndIcon.ContextId = testId;

  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? config.DefaultValue ?? ""),
      Visible: useSignal(config.Visible != false ? true : config.Visible),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      ChipClasses: useSignal(config.ChipClasses ?? []),
      StartIcon: useSignal(config.StartIcon ?? {}),
      EndIcon: useSignal(config.EndIcon ?? {}),
    },
    context
  );

  useSignalEffect(() => {
    if (isNil(config.FormatName)) return;

    const format = GetFormatFromSite(config.FormatName);

    if (isNil(format) || !hasConditionalFormat(format)) return;

    function getMatch() {
      const { form } = getState(context);
      for (const [k, v] of Object.entries(format.ConditionalFormatBindings)) {
        const scope = { Data: { Value: local.Value.value }, Form: form };
        const srcValue = getExprValue(v as any, scope, null);

        if (srcValue === true) {
          return k;
        }

        // console.log("srcValue", srcValue, formCtx);
      }
    }

    const matchedProperty = getMatch();

    if (isNil(matchedProperty) || isNil(format.ConditionalFormats[matchedProperty])) return;

    batch(() => {
      for (const [k, v] of Object.entries(format.ConditionalFormats[matchedProperty])) {
        if (isNil(local[k])) {
          log.error(tag, `The format property '${k}' cannot be dynamically bound to a label`);
          continue;
        }

        if (isObject(v)) local[k].value = { ...config[k], ...v, IgnoreLocalState: true };
      }
    });

    //console.log("Selected Format", format.ConditionalFormats[matchedProperty]);
  });

  config.getData = (e) => {
    return local.Value.value;
  };

  function getFormattedLabel(v) {
    if (isNullOrEmpty(v)) return "";

    if (!isNil(config.NumberFormat)) return formatNumber(v, config.NumberFormat);

    if (!isNil(config.DateFormat)) return formatDate(v, config.DateFormat);

    return v;
  }

  // useSignalEffect(()=>{

  // })

  // if (Array.isArray(local.Value.value)) {
  //   return local.Value.value.map((v, i) => {
  //     return <Chip label={v} key={i} />;
  //   });

  //   const sep = config.ListSeparator ?? ",";
  //   return local.Value.value.join(sep);
  // }

  const isArrayValue: boolean = Array.isArray(local.Value.value);
  const isObjectValue: boolean = !isArrayValue && isObject(local.Value.value);

  const handlers = setupHandlers(config, context);

  function setStyleMultiline(local: any) {
    set(local.Style.value, "display", "-webkit-box");
    set(local.Style.value, "WebkitBoxOrient", "vertical");
    set(local.Style.value, "overflow", "hidden");

    set(local.Style.value, "WebkitLineClamp", config.NumberOfLines ?? 1000);
    set(local.Style.value, "boxSizing", "border-box"); // Include padding and border in the element's total width and height
  }

  function setStyleEllipsis(local: any) {
    if (!config.MultiLine) set(local.Style.value, "whiteSpace", "nowrap");

    set(local.Style.value, "overflow", "hidden");
    set(local.Style.value, "textOverflow", "ellipsis");
  }

  function getLabelStart(
    v: string,
    config: Label,
    local: any,
    getFormattedLabel: (v) => any,
    key: number | undefined,
    addTestId: boolean
  ) {
    // if (isNullOrEmpty(v)) return null;

    if (config.DisplayAsChip && v) {
      return (
        <Chip
          className={getClassName(local.ChipClasses)}
          sx={{ margin: "3px" }}
          key={key}
          label={getLabelWithTooltip(v, config, local, getFormattedLabel, false)}
        />
      );
    }

    return getLabelWithTooltip(v, config, local, getFormattedLabel, addTestId);
  }

  function getLabelWithTooltip(
    v: string,
    config: Label,
    local: any,
    getFormattedLabel: (v) => any,
    addTestId: boolean
  ) {
    if (config.EllipsisOnOverflow) {
      setStyleEllipsis(local);
      return (
        <Tooltip
          data-testid={addTestId ? getTestId(config) : undefined}
          data-type={addTestId ? config.__typename : undefined}
          title={getFormattedLabel(v)}
        >
          {getLabel(v, local, getFormattedLabel, false)}
        </Tooltip>
      );
    }

    return getLabel(v, local, getFormattedLabel, addTestId);
  }

  function getLabel(v, local: any, getFormattedLabel: (v) => any, addTestId: boolean) {
    if (config.MultiLine) setStyleMultiline(local);

    const s = getsxObject(local.Style.value, {
      display: "flex",
      alignItems: "center",
    });

    v = getCustomLabel(v);
    return (
      <Typography
        id={config.SetAsBookmark ? config.Id : undefined}
        data-testid={addTestId ? getTestId(config) : undefined}
        data-type={addTestId ? config.__typename : undefined}
        sx={s}
        className={getClassName(local.Classes, context)}
        onClick={(e) => {
          if ((handlers as any).onClick) (handlers as any).onClick(e);
        }}
      >
        {local.StartIcon.value && !isNullOrEmpty(local.StartIcon.value.IconName) && (
          <NGIcon config={{ ...local.StartIcon.value, ContextId: testId }} context={context} />
        )}
        {getFormattedLabel(v)}
        {local.EndIcon.value && !isNullOrEmpty(local.EndIcon.value.IconName) && (
          <NGIcon config={{ ...local.EndIcon.value, ContextId: testId }} context={context} />
        )}
      </Typography>
    );
  }

  const valueAsChip = isArray(local.Value.value)
    ? local.Value.value
    : isString(local.Value.value)
    ? local.Value.value.split(",")
    : [];

  return (
    <>
      {isObjectValue && "This label is an object, which is not supported.  Correct your binding."}
      {local.Visible.value && !isObjectValue && config.DisplayAsChip && (
        <div data-testid={getTestId(config)} data-type={config.__typename}>
          {valueAsChip?.map((v, i) => {
            return getLabelStart(v, config, local, getFormattedLabel, i, false);
          })}
        </div>
      )}
      {local.Visible.value &&
        !isObjectValue &&
        !isArrayValue &&
        !config.DisplayAsChip &&
        getLabelStart(
          isArrayValue ? local.Value.value.join(config.ListSeparator ?? ",") : local.Value.value,
          config,
          local,
          getFormattedLabel,
          undefined,
          true
        )}
    </>
  );
}

// {local.Visible.value && (
//   <InputLabel
//     sx={getsxObject(config.Style)}
//     className={config.ClassName as string}
//     disabled={config.Disabled as boolean}
//     disableAnimation={config.DisableAnimation as boolean}
//     error={config.Error as boolean}
//     filled={config.Filled as boolean}
//     focused={config.Focused as boolean}
//     required={config.Required as boolean}
//     shrink={config.Shrink as boolean}
//     // variant={config.Variant as any}
//   >
//     {getLabelWithTypography()}
//   </InputLabel>

// )}

// function getLabelWithTypography() {
//   const style: React.CSSProperties = {};

//   // if (props.layoutType == "GridStackLayout") {
//   //   style = {
//   //     display: "-webkit-box",
//   //     WebkitBoxOrient: "vertical",
//   //     overflow: "hidden",
//   //     textOverflow: "ellipsis",
//   //     WebkitLineClamp: config.MultiLine ? config.NumberOfLines ?? 3 : 1,
//   //     // Adjust the below properties as needed based on the width of the container
//   //     width: "100%", // Ensure the typography takes the full width it can get
//   //     boxSizing: "border-box", // Include padding and border in the element's total width and height
//   //   };
//   // } else if (props.layoutType == "LinearLayout") {
//   //   style = {
//   //     display: "-webkit-box",
//   //     WebkitBoxOrient: "vertical",
//   //     overflow: "hidden",
//   //     textOverflow: "ellipsis",
//   //     WebkitLineClamp: config.MultiLine ? config.NumberOfLines ?? 3 : 1000,
//   //     // Adjust the below properties as needed based on the width of the container
//   //     width: "100%", // Ensure the typography takes the full width it can get
//   //     boxSizing: "border-box", // Include padding and border in the element's total width and height
//   //   };
//   // }

//   // if (config.Header && !isNil(config.HeaderRank))
//   //   return (
//   //     <Typography variant={`h${config.HeaderRank}` as any}>
//   //       {getFormattedLabel()}
//   //     </Typography>
//   //   );
//   // else return <Typography style={style}>{getFormattedLabel()}</Typography>;

//   return <Typography style={style}>{getFormattedLabel()}</Typography>;
// }
