import * as graphql from "../../resolvers-types";
import { dmeLeftDrawer } from "./data-model-editor/dme-left-drawer";
import { menuSample } from "./menuSample";

export const drawerContent: graphql.Page = {
  __typename: "Page",
  Id: "0x4a5",
  Name: "My CRM Dashboard",
  Items: [
    {
      __typename: "SimpleContainer",
      Id: "0x4a9",
      UniqueName: "ClientDetails",
      Items: [
        {
          Typename: "Form",
          Id: "0x4a17",
          Items: [
            {
              __typename: "Label",
              Id: "0x4a18",
              Sequence: 1,
              DefaultValue: "MyLabel2",
            },
          ],
        } as graphql.Form,
      ],
    },
  ],
  Layout: {
    __typename: "LinearLayout",
    Id: "0xc0003",
  },
};

const footerContent: graphql.Page = {
  __typename: "Page",
  Id: "0x5a5",
  UniqueName: "Footer1",
  Name: "Footer1",
  Items: [
    {
      __typename: "SimpleContainer",
      Id: "0x5a9",
      UniqueName: "Footer",
      Items: [
        {
          Typename: "Form",
          Id: "0x5a17",
          Items: [
            {
              __typename: "Label",
              Id: "0x5a18",
              Sequence: 1,
              DefaultValue: "MyFooter",
            },
            {
              __typename: "Label",
              Id: "0x5a19",
              Sequence: 2,
              DefaultValue: "Is Here",
            },
          ],
        } as graphql.Form,
      ],
    },
  ],
  Layout: {
    __typename: "LinearLayout",
    Id: "0xc0003",
    DirectionExtraSmall: "row",
    DirectionSmall: "row",
    DirectionMedium: "row",
    DirectionLarge: "row",
    DirectionExtraLarge: "row",
  },
};

export const siteConfig: graphql.PageTemplate = {
  __typename: "PageTemplate",
  Id: "0xf021",
  UniqueName: "Demo Site",
  Description: "Demo Site",
  TopAppBar: {
    Id: "0xf022",
    UniqueName: "Top Navigation",
    //Menu: menuSample,
  },
  // Header: {
  //   Id: "0xf023",
  //   UniqueName: "Left Navigation",
  //   Anchor: "top",
  //   Variant: "permanent",
  //   Open: true,
  //   //Content: footerContent,
  //   ComponentName: "tlc-header",
  // },

  // // LeftSidebar: {
  // //   Id: "0xf023",
  // //   UniqueName: "Left Navigation",
  // //   Anchor: "left",
  // //   Variant: "permanent",
  // //   Open: true,
  // //   Content: dmeLeftDrawer,
  // // },
  // RightSidebar: {
  //   Id: "0xf023",
  //   UniqueName: "Left Navigation",
  //   Anchor: "right",
  //   Variant: "",
  //   Open: true,
  //   Content: drawerContent,
  // },
  // Footer: {
  //   Id: "0xf024",
  //   UniqueName: "Footer",
  //   Anchor: "bottom",
  //   Variant: "persistent",
  //   Open: true,
  //   Content: footerContent,
  // },
};

export const anonymousSiteConfig: graphql.PageTemplate = {
  __typename: "PageTemplate",
  Id: "/1",
  UniqueName: "Demo Site",
  Description: "Demo Site",
  TopAppBar: {
    Id: "/1/1",
    UniqueName: "Top Navigation",
  },
  // LeftSidebar: {
  //   Id: "/1/2",
  //   UniqueName: "Left Navigation",
  //   Anchor: "left",
  //   Variant: "permanent",
  //   Open: true,
  //   Content: dmeLeftDrawer,
  // },
  /* "RightSidebar": {
        "Id": "0xf023",
        "UniqueName": "Left Navigation",
        "Anchor": "right",
        "Variant": "",
        "Open": true,
        "Content": drawerContent
    }, 
    "Footer": {
        "Id": "0xf024",
        "UniqueName": "Footer",
        "Anchor": "bottom",
        "Variant": "persistent",
        "Open": true,
        "Content": footerContent
    },
*/
};
