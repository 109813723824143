import { DropkiqEngine } from "dropkiq";
import { BoundElement } from "./BoundElement";
import tippy from "tippy.js";
import { v4 as uuidv4 } from "uuid";

import createDOMPurify from "dompurify";
const DOMPurify = createDOMPurify(window);

enum ColumnType {
  Boolean = "ColumnTypes::Boolean",
  DateTime = "ColumnTypes::DateTime",
  HasMany = "ColumnTypes::HasMany",
  HasOne = "ColumnTypes::HasOne",
  Numeric = "ColumnTypes::Numeric",
  String = "ColumnTypes::String",
  Text = "ColumnTypes::Text",
  YAML = "ColumnTypes::YAML",
}

interface Suggestion {
  active?: boolean;
  foreign_table_name: string | null;
  hint?: string;
  iconImageURLForSuggestion: string;
  insertionTemplate?: string;
  name: string;
  nameWithoutPrefix: string;
  prefix?: string;
  preview?: string;
  selectRange?: Array<number>;
  template: string;
  type: ColumnType;
}

interface DropkiqOptions {
  iframe?: HTMLIFrameElement;
  onRender?: (renderedDocument: string) => void;
  showHints?: () => boolean;
  showPreviews?: () => boolean;
  suggestionFilter?: (suggestions: Suggestion[]) => void;
}

export class DropkiqUI {
  public isEnabled: boolean;
  public element: any;
  public boundElement: BoundElement;
  public schema: object;
  public context: object;
  public scope: object;
  public licenseKey: string;
  private savedSelection: any = null;

  public options: DropkiqOptions;
  public showPreviews: () => boolean;
  public showHints: () => boolean;
  public suggestionFilter: (suggestions: Suggestion[]) => void;
  public onRender: (renderedDocument: string) => void;

  public iframe: any;
  public document: any;
  public window: any;
  public pathSchema: [];

  private dropkiqEngine: any;
  private suggestionsArray: Array<Suggestion>;
  private result: object;
  private caretOffset: object;
  private $ul: any;
  private $header: any;
  private $div: any;
  private documentCallback: any;

  constructor(
    element,
    schema: object,
    context: object,
    scope: object,
    licenseKey = "",
    options: DropkiqOptions = {}
  ) {
    this.schema = schema;
    this.context = context;
    this.scope = scope;
    this.licenseKey = licenseKey;

    this.options = options;
    this.showPreviews =
      typeof options["showPreviews"] === "function"
        ? options["showPreviews"]
        : () => true;
    this.showHints =
      typeof options["showHints"] === "function"
        ? options["showHints"]
        : () => true;
    this.suggestionFilter =
      typeof options["suggestionFilter"] === "function"
        ? options["suggestionFilter"]
        : () => {};
    this.onRender =
      typeof options["onRender"] === "function"
        ? options["onRender"]
        : () => {};
    this.iframe = options["iframe"];

    if (this.iframe) {
      this.window = this.iframe.contentWindow;
      this.document = this.window.document;
    } else {
      this.window = window;
      this.document = document;
    }

    this.element = element;

    if (!this.window.dropkiqUIInstances) {
      this.window.dropkiqUIInstances = {};
    }

    let dropkiqUUID;
    if (this.element.dataset) {
      dropkiqUUID = this.element.dataset.dropkiqUUID;
    }

    const existingInstance = this.window.dropkiqUIInstances[dropkiqUUID];

    if (existingInstance) {
      return existingInstance;
    } else {
      if (this.element.dataset) {
        dropkiqUUID = uuidv4();

        this.element.dataset.dropkiqUUID = dropkiqUUID;
        this.window.dropkiqUIInstances[dropkiqUUID] = this;
      }
    }

    this.boundElement = new BoundElement(
      this.element,
      this.window,
      this.document
    );

    this.dropkiqEngine = new DropkiqEngine(
      "",
      0,
      schema,
      context,
      scope,
      this.licenseKey,
      { suggestionFilter: this.suggestionFilter }
    );
    this.suggestionsArray = [];
    this.result = {};
    this.caretOffset = {};
    this.pathSchema = [];

    // Initialize DOM elements for suggestions
    this.initializeDOMElements();

    const that = this;

    this.documentCallback = function (e) {
      if (!that.$div.contains(e.target)) {
        that.closeMenu();
      }
    };

    const scrollCallback = function () {
      that.closeMenu();
    };

    const keydownCallback = (e: KeyboardEvent) => {
      if (!this.isEnabled) return; // Ignore events if disabled

      if (this.suggestionsArray.length) {
        let suggestion;

        switch (e.keyCode) {
          case 27: // Esc key
            this.closeMenu();
            e.preventDefault();
            return false;
          case 38: // up arrow
            this.scrollToPrevious();
            e.preventDefault();
            return false;
          case 40: // down arrow
            this.scrollToNext();
            e.preventDefault();
            return false;
          case 9: // tab
          case 13: // enter key
            suggestion = this.suggestionsArray.find((s) => s.active);
            if (suggestion) {
              this.insertSuggestion(suggestion);
              e.preventDefault();
              return false;
            }
            break;
          default:
            break;
        }
      }

      // Auto-complete {{}} and {%%}
      setTimeout(() => {
        if (!this.isEnabled) return; // Prevent auto-complete when disabled

        const result = this.boundElement.caretPositionWithDocumentInfo();

        const selectionStart = result["selectionStart"];
        const leftText = result["leftText"];
        const rightText = result["rightText"];
        const leftTwoCharacters = leftText.slice(-2);
        const closeTagPattern = /^(\s+)?\}(.+)?/;

        if (
          e.keyCode === 219 &&
          e.shiftKey &&
          (leftTwoCharacters[1] === "{" || leftTwoCharacters === "{")
        ) {
          const textNode = this.boundElement.insertTextAtCaret("}");
          this.boundElement.setCaretPosition(
            selectionStart,
            0,
            0,
            textNode,
            ""
          );
          this.element.focus();
        } else if (
          e.keyCode === 53 &&
          e.shiftKey &&
          leftTwoCharacters === "{%" &&
          closeTagPattern.test(rightText)
        ) {
          const textNode = this.boundElement.insertTextAtCaret("%");
          this.boundElement.setCaretPosition(
            selectionStart,
            0,
            0,
            textNode,
            ""
          );
          this.element.focus();
        }
      }, 25);

      findResultsCallback(e);
    };

    const findResultsCallback = function (e) {
      if (e && typeof e.stopImmediatePropagation === "function") {
        e.stopImmediatePropagation();
      }

      setTimeout(function () {
        that.findResults.apply(that);
      }, 25);
    };

    const onBlurCallback = function (e) {
      const sel = that.window.getSelection();
      const range = sel.getRangeAt(0);
      that.boundElement.setExpiringCachedOnBlurRange(range);
    };

    // Attach event listeners based on the editor type

    this.element.addEventListener("keydown", keydownCallback);
    this.element.addEventListener("click", findResultsCallback);
    this.element.addEventListener("focus", findResultsCallback);
    this.element.addEventListener("blur", onBlurCallback);
    this.element.addEventListener("scroll", scrollCallback);

    this.isEnabled = true;
  }

  private initializeDOMElements() {
    this.$ul = this.document.createElement("ul");
    this.$header = this.document.createElement("div");
    this.$header.setAttribute("class", "dropkiq-header");
    this.$div = this.document.createElement("div");
    this.$div.setAttribute("id", "dropkiq-autosuggest-menu");
    this.$div.appendChild(this.$header);
    this.$div.appendChild(this.$ul);
    this.document.body.appendChild(this.$div);
  }

  public disable() {
    if (!this.isEnabled) return; // Already disabled

    this.isEnabled = false;

    // Hide the suggestion modal
    if (this.$div) {
      this.$div.style.display = "none";
    }

    console.log("DropkiqUI instance disabled.");
  }

  public enable() {
    if (this.isEnabled) return; // Already enabled

    this.isEnabled = true;

    // Show the suggestion modal if there are suggestions
    if (this.suggestionsArray.length) {
      this.$div.style.display = "block";
    }

    console.log("DropkiqUI instance enabled.");

    // Optionally, trigger a re-evaluation to show suggestions immediately
    this.findResults();
  }

  public destroy() {
    // Remove event listeners

    this.element.removeEventListener("keydown", this.documentCallback);
    this.element.removeEventListener("click", this.documentCallback);
    this.element.removeEventListener("focus", this.documentCallback);
    this.element.removeEventListener("blur", this.documentCallback);
    this.element.removeEventListener("scroll", this.documentCallback);

    // Remove document event listener
    this.removeDocumentEventListeners();

    // Remove elements from DOM
    if (this.$div) {
      this.$div.remove();
    }

    // Clean up references
    this.dropkiqEngine = null;
    this.boundElement = null; // Make sure to clear the bound element reference
    this.suggestionsArray = [];
    this.result = {};
    this.caretOffset = {};
    this.$ul = null;
    this.$header = null;
    this.$div = null;
    this.documentCallback = null;

    // Remove the instance from the window.dropkiqUIInstances
    if (this.window.dropkiqUIInstances && this.element.dataset.dropkiqUUID) {
      delete this.window.dropkiqUIInstances[this.element.dataset.dropkiqUUID];
    }

    console.log("DropkiqUI instance destroyed.");
  }

  public updateScope(scope: object) {
    this.scope = scope;
    this.dropkiqEngine.updateScope(this.scope);
  }

  public registerFilter(
    name: string,
    filter: Function,
    template: string,
    selectionRange: Array<number>,
    hint?: string
  ) {
    this.dropkiqEngine.registerFilter(
      name,
      filter,
      template,
      selectionRange,
      hint
    );
  }

  public menuIsOpen() {
    return this.suggestionsArray.length > 0;
  }

  public closeMenu() {
    this.removeDocumentEventListeners();
    this.suggestionsArray = [];
    this.renderSuggestions();
  }

  private removeDocumentEventListeners() {
    this.document.removeEventListener("click", this.documentCallback);

    if (this.document && this.document !== document) {
      this.document.removeEventListener("click", this.documentCallback);
    }
  }

  private renderSuggestions() {
    const prefix = this.result["prefix"];

    this.$header.innerHTML = "";
    this.$header.style.display = "none";

    let lastPathNode;
    if (this.pathSchema) {
      lastPathNode = this.pathSchema[this.pathSchema.length - 1];
    }

    if (lastPathNode && lastPathNode.type === "ColumnTypes::HasOne") {
      const imgUrl = "https://app.dropkiq.com/plugin/object.png";
      const $icon = this.document.createElement("img");

      $icon.setAttribute("src", imgUrl);
      $icon.setAttribute("class", "icon");
      $icon.setAttribute("width", "16px");
      $icon.setAttribute("height", "16px");

      const $text = this.document.createElement("span");
      $text.textContent = lastPathNode.name;

      this.$header.appendChild($icon);
      this.$header.appendChild($text);
      this.$header.style.display = "block";
    }

    this.$div.style.top = `${this.caretOffset["top"]}px`;
    this.$div.style.left = `${this.caretOffset["left"]}px`;

    if (this.suggestionsArray.length) {
      this.$div.style.display = "block";
    } else {
      this.$div.style.display = "none";
    }
    this.$ul.innerHTML = "";

    const that = this;
    this.suggestionsArray.forEach(function (suggestion) {
      const $li = that.document.createElement("li");

      const imgUrl = suggestion["iconImageURLForSuggestion"];
      const $icon = that.document.createElement("img");
      $icon.setAttribute("src", imgUrl);
      $icon.setAttribute("class", "icon");
      $icon.setAttribute("width", "16px");
      $icon.setAttribute("height", "16px");

      const $entire = that.document.createElement("div");
      $entire.setAttribute("class", "first-line");

      const $extra = that.document.createElement("div");
      $extra.setAttribute("class", "extra");
      const $remaining = that.document.createElement("span");
      const $arrowSpan = that.document.createElement("img");
      $arrowSpan.setAttribute("class", "right-arrow");
      $arrowSpan.setAttribute(
        "src",
        "https://app.dropkiq.com/plugin/next-level.png"
      );

      $entire.appendChild($icon);
      $entire.appendChild($arrowSpan);

      if (prefix) {
        const $strong = that.document.createElement("strong");

        $strong.textContent = prefix;
        const suggestionName = suggestion["name"];
        $remaining.textContent = suggestionName.slice(
          prefix.length,
          suggestionName.length
        );
        $entire.appendChild($strong);
      } else {
        $remaining.textContent = suggestion["name"];
      }

      $entire.appendChild($remaining);
      $li.appendChild($entire);
      $li.setAttribute("title", that.suggestionTitleText(suggestion));

      if (suggestion["hint"] && that.showHints()) {
        const $hintSpan = that.document.createElement("div");
        $hintSpan.setAttribute("class", "hint-icon");
        $hintSpan.setAttribute("data-tippy-content", suggestion["hint"]);
        $hintSpan.setAttribute("title", "");

        const imgUrl = "https://app.dropkiq.com/plugin/question-circle.png";
        const $hint = that.document.createElement("img");
        $hint.setAttribute("src", imgUrl);

        $hintSpan.appendChild($hint);
        $li.appendChild($hintSpan);
      }

      if (suggestion["preview"] && that.showPreviews()) {
        const $head = that.document.createElement("p");
        $head.textContent = "OUTPUT";

        const $samp = that.document.createElement("div");
        $samp.innerHTML = DOMPurify.sanitize(suggestion["preview"]);

        $extra.appendChild($head);
        $extra.appendChild($samp);
        $li.appendChild($extra);
      }

      if (suggestion["active"]) {
        $li.classList.add("active");
      }
      that.$ul.appendChild($li);

      $li.addEventListener("mousedown", function (e) {
        e.preventDefault();
        e.stopPropagation();

        that.boundElement.setFocus();
        that.boundElement.restoreSelection();

        that.insertSuggestion(suggestion);
      });
    });

    const activeLi = this.$ul.querySelector(".active");
    if (activeLi) {
      this.$ul.scrollTop = activeLi.offsetTop - 50;
    }

    that.removeDocumentEventListeners();
    setTimeout(function () {
      that.document.addEventListener("click", that.documentCallback);
      if (that.document && that.document !== document) {
        that.document.addEventListener("click", that.documentCallback);
      }
    }, 100);

    tippy(".hint-icon");
  }

  private findResults() {
    try {
      const result = this.boundElement.caretPositionWithDocumentInfo();
      this.caretOffset = this.boundElement.getCaretPosition();

      if (this.iframe) {
        const iframeRect = this.iframe.getBoundingClientRect();
        this.caretOffset["top"] += iframeRect.top;
        this.caretOffset["left"] += iframeRect.left;
      }

      this.result = this.dropkiqEngine.update(
        result["allText"],
        result["selectionStart"]
      );

      this.savedSelection = this.boundElement.saveSelection();
    } catch (error) {
      this.closeMenu();

      if (error.name === "ParseError" || error.name === "RenderError") {
        return false;
      } else {
        throw error;
      }
    }

    this.onRender(this.result["renderedDocument"]);
    this.pathSchema = this.result["pathSchema"];
    this.suggestionsArray = this.result["suggestionsArray"] || [];

    if (this.suggestionsArray.length > 0) {
      this.suggestionsArray.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.suggestionsArray[0]["active"] = true;
    }

    this.renderSuggestions();
  }

  private insertSuggestion(suggestion: Suggestion) {
    const prefix = this.result["prefix"];
    let suggestionText: string;
    let caretPositionWithDocumentInfo: object;

    if (suggestion.type === ColumnType.Filter) {
      caretPositionWithDocumentInfo =
        this.boundElement.caretPositionWithDocumentInfo();
      suggestionText = suggestion["insertionTemplate"];
    } else {
      suggestionText = suggestion["name"];
    }

    let textToEnter = suggestionText.slice(prefix.length);

    if (suggestion.type === ColumnType.HasOne) {
      textToEnter += ".";
    }

    // Restore focus and selection
    this.boundElement.setFocus();
    this.boundElement.restoreSelection();

    const textNode = this.boundElement.insertTextAtCaret(textToEnter);

    if (suggestion.type === ColumnType.Filter) {
      const startSelect = suggestion["selectRange"][0];
      const endSelect = suggestion["selectRange"][1];

      this.boundElement.setCaretPosition(
        caretPositionWithDocumentInfo["selectionStart"],
        startSelect,
        endSelect,
        textNode,
        prefix
      );
    }

    this.closeMenu();

    setTimeout(() => {
      this.findResults();
    }, 25);
  }

  private scrollToNext() {
    const activeSuggestion = this.suggestionsArray.find(
      (suggestion) => suggestion["active"]
    );
    const activeIndex = this.suggestionsArray.indexOf(activeSuggestion);

    if (activeIndex === -1) return;

    this.suggestionsArray[activeIndex]["active"] = false;

    if (this.suggestionsArray[activeIndex + 1]) {
      this.suggestionsArray[activeIndex + 1]["active"] = true;
    } else {
      this.suggestionsArray[0]["active"] = true;
    }

    this.renderSuggestions();
  }

  private scrollToPrevious() {
    const activeSuggestion = this.suggestionsArray.find(
      (suggestion) => suggestion["active"]
    );
    const activeIndex = this.suggestionsArray.indexOf(activeSuggestion);

    if (activeIndex === -1) return;

    this.suggestionsArray[activeIndex]["active"] = false;

    if (this.suggestionsArray[activeIndex - 1]) {
      this.suggestionsArray[activeIndex - 1]["active"] = true;
    } else {
      this.suggestionsArray[this.suggestionsArray.length - 1]["active"] = true;
    }

    this.renderSuggestions();
  }

  private suggestionTitleText(suggestion: Suggestion): string {
    const suggestionTexts = [suggestion["name"]];

    if (suggestion.preview) {
      suggestionTexts.push(`**OUTPUT** ${suggestion.preview}`);
    }

    if (suggestion["hint"]) {
      suggestionTexts.push(`**HINT** ${suggestion["hint"]}`);
    }

    return suggestionTexts.join(" ");
  }
}
