import NGPage from "../../generators/NGPage";
import NGReference from "../../generators/NGReference";
import { Layout } from "../../gql/graphql";
import { INGDrawerProps } from "../../library/NGFieldExtensions";
import { Drawer as MUIDrawer } from "@mui/material";
import { getClassNameFromString, getsxObject } from "../../library/utils";

export default function NGDrawer({ config, context }: INGDrawerProps) {
  return (
    <>
      <MUIDrawer
        sx={getsxObject(config.Style, {
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: config.Width,
            boxSizing: "border-box",
            height: config.Height,
            marginLeft: config.MarginLeft,
            marginRight: config.MarginRight,
            marginTop: config.MarginTop,
            marginBottom: config.MarginBottom,
          },
        })}
        className={getClassNameFromString(config.Classes)}
        anchor={config.Anchor as any}
        hideBackdrop={config.HideBackdrop as boolean}
        open={config.Open as boolean}
        transitionDuration={config.TransitionDuration as any}
        variant={config.Variant as any}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {config.Content?.__typename == "Page" && (
          <NGPage config={config.Content} layout={config.Content.Layout as Layout} context={context} />
        )}
        {config.ComponentName && (
          <NGReference
            config={
              {
                __typename: "Reference",
                Id: config.Id + "_ref",
                ReferenceType: "Component",
                ReferenceId: config.ComponentName,
              } as any
            }
            context={context}
          />
        )}
      </MUIDrawer>
    </>
  );
}
