import { ICellRendererParams } from 'ag-grid-charts-enterprise'
import { Typography } from '@mui/material';
import { generateUID } from '../../library/utils'
import { LayoutItem, Maybe } from '../../../resolvers-types';
import NGLayoutItem from '../../generators/NGLayoutItem';
import { getBindings } from './NGListUtils';

interface CellRendererListProps extends ICellRendererParams<unknown> {
  components?: Maybe<LayoutItem>[] | null;
}

export default function NGCellRendererList({ context, components, ...params }: CellRendererListProps) {
  return (
    <Typography
      component="span"
      id={generateUID() ?? undefined}
      display="flex"
      alignItems="center"
      height="100%"
      width="100%"
    >
      {components?.map((layoutItem) =>
        <NGLayoutItem
          key={layoutItem?.Id ?? generateUID()}
          config={getBindings({ params, context, config: layoutItem })}
          context={context}
        />
      )}
    </Typography>
  )
}

