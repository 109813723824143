import { useSignal, signal, useSignalEffect } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { log } from "../../library/logger";
import { INGMarkdownProps } from "../../library/NGFieldExtensions";
import { getClassName, getTestId, getsxObject } from "../../library/utils";
import { Box } from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const tag = "NGMarkdown";

export default function NGMarkdown({ config, context }: INGMarkdownProps) {
  log.info(tag, "creating markdown");

  const local = setupLocalState(
    config,
    {
      Value: useSignal(config.Value ?? ""),
      Visible: useSignal(config.Visible ?? true),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  return (
    <>
      {local.Visible.value && local.Value.value && (
        <Box
          data-testid={getTestId(config)}
          data-type={config.__typename}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
          {...handlers}
        >
          <Markdown remarkPlugins={[remarkGfm]}>{local.Value.value}</Markdown>
        </Box>
      )}
    </>
  );
}
