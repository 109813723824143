export class BoundElement {
  public element: any;
  public window: any;
  public document: any;
  public isContenteditable: boolean;
  public savedSelectionRange: Range | { start: number; end: number } | null =
    null;
  public cachedOnBlurRange: any;

  constructor(element, window, document) {
    this.element = element;
    this.window = window;
    this.document = document;
    this.isContenteditable = this.element.isContentEditable;
    this.cachedOnBlurRange = null;
  }

  public setFocus() {
    this.element.focus(); // Directly focus the element
  }

  public saveSelection(): Range | { start: number; end: number } | null {
    if (this.isContenteditable) {
      const sel = this.window.getSelection();
      if (sel && sel.rangeCount > 0) {
        this.savedSelectionRange = sel.getRangeAt(0).cloneRange();
      }
    } else {
      this.savedSelectionRange = {
        start: this.element.selectionStart,
        end: this.element.selectionEnd,
      };
    }
    return this.savedSelectionRange;
  }

  public restoreSelection() {
    if (this.isContenteditable && this.savedSelectionRange instanceof Range) {
      const sel = this.window.getSelection();
      sel.removeAllRanges();
      sel.addRange(this.savedSelectionRange);
    } else if (
      !this.isContenteditable &&
      this.savedSelectionRange &&
      typeof this.savedSelectionRange.start === "number"
    ) {
      this.element.focus();
      this.element.setSelectionRange(
        this.savedSelectionRange.start,
        this.savedSelectionRange.end
      );
    }
  }

  public caretPositionWithDocumentInfo(): object {
    if (this.isContenteditable) {
      return this.caretPositionWithDocumentInfoForContenteditable();
    } else {
      return this.caretPositionWithDocumentInfoForInput();
    }
  }

  // Position is for input, textNode is for contenteditable
  public setCaretPosition(caretIndex, start, end, textNode, prefix) {
    const caretWordStart = caretIndex - prefix.length;

    if (this.isContenteditable) {
      const range = this.document.createRange();
      const sel = this.window.getSelection();
      range.setStart(textNode, start - prefix.length);
      range.setEnd(textNode, end - prefix.length);
      sel.removeAllRanges();
      sel.addRange(range);
    } else {
      const newCaretStart = caretWordStart + start;
      const newCaretEnd = caretWordStart + end;

      this.element.setSelectionRange(newCaretStart, newCaretEnd);
    }
  }

  public getCaretPosition() {
    if (this.isContenteditable) {
      const selection = this.window.getSelection();
      const range = selection.getRangeAt(0);
      return this.getContentEditableCaretPosition(range.startOffset);
    } else {
      const caretPosition = this.element.selectionStart;
      return this.getTextAreaOrInputUnderlinePosition(
        this.element,
        caretPosition,
        false
      );
    }
  }

  public insertTextAtCaret(text) {
    if (this.isContenteditable) {
      return this.insertTextForContenteditable(text);
    } else {
      return this.insertTextForInput(text);
    }
  }

  public setExpiringCachedOnBlurRange(range: any) {
    if (this.isContenteditable) {
      this.cachedOnBlurRange = range;

      setTimeout(() => {
        this.cachedOnBlurRange = null;
      }, 200);
    }
  }

  /////////////////////////////////////////////////////////////////////////////

  private insertTextForContenteditable(text) {
    const sel = this.window.getSelection();
    const range = sel.getRangeAt(0);

    range.deleteContents();
    const textNode = this.document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);

    return textNode;
  }

  private insertTextForInput(text) {
    const textarea = this.element;
    const scrollPos = textarea.scrollTop;
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;

    const front = textarea.value.substring(0, selectionStart);
    const back = textarea.value.substring(selectionEnd, textarea.value.length);
    console.log({ front, text, back });
    textarea.value = front + text + back;

    const newCaretPos = selectionStart + text.length;
    textarea.setSelectionRange(newCaretPos, newCaretPos);

    textarea.scrollTop = scrollPos;

    // Dispatch an 'input' event to notify listeners of the change
    const event = new Event("input", { bubbles: true });
    textarea.dispatchEvent(event);
    console.log({ textarea });

    return textarea;
  }

  public caretPositionWithDocumentInfoForInput(): object {
    const selectionStart = this.element.selectionStart;
    const value = this.element.value;
    const leftText = value.slice(0, selectionStart);
    const rightText = value.slice(selectionStart, value.length);

    return {
      leftText: leftText,
      selectionStart: selectionStart,
      rightText: rightText,
      allText: leftText + rightText,
    };
  }

  public caretPositionWithDocumentInfoForContenteditable(): object {
    const selection = this.window.getSelection();
    const range = selection.getRangeAt(0);

    // Left
    const leftRange = range.cloneRange();
    leftRange.setStart(this.element, 0);
    leftRange.setEnd(range.startContainer, range.startOffset);
    const leftText = this.captureRangeText(leftRange);

    // Right
    const rightRange = range.cloneRange();
    rightRange.selectNodeContents(this.element);
    rightRange.setStart(range.startContainer, range.startOffset);
    const rightText = this.captureRangeText(rightRange);

    return {
      leftText: leftText,
      selectionStart: leftText.length,
      rightText: rightText,
      allText: leftText + rightText,
    };
  }

  private captureRangeText(range): string {
    const documentFragment = range.cloneContents();

    const elemClone = this.document.createElement("div");
    elemClone.setAttribute(
      "style",
      "position: absolute; left: -10000px; top: -10000px"
    );
    elemClone.setAttribute("contenteditable", "true");

    elemClone.appendChild(documentFragment);
    this.document.body.appendChild(elemClone);
    const captureText = elemClone.innerText;
    elemClone.remove();

    return captureText;
  }

  private getTextAreaOrInputCaretPosition(element, position) {
    // Create a mirror div to calculate caret position
    const properties = [
      "direction",
      "boxSizing",
      "width",
      "height",
      "overflowX",
      "overflowY",
      "borderTopWidth",
      "borderRightWidth",
      "borderBottomWidth",
      "borderLeftWidth",
      "paddingTop",
      "paddingRight",
      "paddingBottom",
      "paddingLeft",
      "fontStyle",
      "fontVariant",
      "fontWeight",
      "fontStretch",
      "fontSize",
      "fontSizeAdjust",
      "lineHeight",
      "fontFamily",
      "textAlign",
      "textTransform",
      "textIndent",
      "textDecoration",
      "letterSpacing",
      "wordSpacing",
    ];

    const div = this.document.createElement("div");
    div.id = "input-textarea-caret-position-mirror-div";
    this.document.body.appendChild(div);

    const style = div.style;
    const computed = this.window.getComputedStyle
      ? getComputedStyle(element)
      : element.currentStyle;

    // Copy properties
    properties.forEach((prop) => {
      style[prop] = computed[prop];
    });

    // Additional styles to mimic the textarea/input
    style.whiteSpace = "pre-wrap";
    if (element.nodeName !== "INPUT") {
      style.wordWrap = "break-word";
    }

    // Position off-screen
    style.position = "absolute";
    style.visibility = "hidden";

    // Set text content up to caret position
    div.textContent = element.value.substring(0, position);

    if (element.nodeName === "INPUT") {
      div.textContent = div.textContent.replace(/\s/g, " ");
    }

    // Insert a span to calculate caret position
    const span = this.document.createElement("span");
    span.textContent = element.value.substring(position) || ".";
    div.appendChild(span);

    const rect = element.getBoundingClientRect();
    const windowLeft =
      (this.window.pageXOffset || this.document.documentElement.scrollLeft) -
      (this.document.documentElement.clientLeft || 0);
    const windowTop =
      (this.window.pageYOffset || this.document.documentElement.scrollTop) -
      (this.document.documentElement.clientTop || 0);

    const coordinates = {
      top:
        rect.top +
        windowTop +
        span.offsetTop +
        parseInt(computed.borderTopWidth) +
        parseInt(computed.fontSize),
      left:
        rect.left +
        windowLeft +
        span.offsetLeft +
        parseInt(computed.borderLeftWidth),
    };

    this.document.body.removeChild(div);
    return coordinates;
  }
  private getTextAreaOrInputUnderlinePosition(element, position, flipped) {
    const properties = [
      "direction",
      "boxSizing",
      "width",
      "height",
      "overflowX",
      "overflowY",
      "borderTopWidth",
      "borderRightWidth",
      "borderBottomWidth",
      "borderLeftWidth",
      "paddingTop",
      "paddingRight",
      "paddingBottom",
      "paddingLeft",
      "fontStyle",
      "fontVariant",
      "fontWeight",
      "fontStretch",
      "fontSize",
      "fontSizeAdjust",
      "lineHeight",
      "fontFamily",
      "textAlign",
      "textTransform",
      "textIndent",
      "textDecoration",
      "letterSpacing",
      "wordSpacing",
    ];

    const isFirefox = this.window["mozInnerScreenX"] !== null;

    const div = this.document.createElement("div");
    div.id = "input-textarea-caret-position-mirror-div";
    this.document.body.appendChild(div);

    const style = div.style;
    const computed = this.window.getComputedStyle
      ? getComputedStyle(element)
      : element.currentStyle;

    style.whiteSpace = "pre-wrap";
    if (element.nodeName !== "INPUT") {
      style.wordWrap = "break-word";
    }

    // position off-screen
    style.position = "absolute";
    style.visibility = "hidden";

    // transfer the element's properties to the div
    properties.forEach((prop) => {
      style[prop] = computed[prop];
    });

    if (isFirefox) {
      style.width = `${parseInt(computed.width) - 2}px`;
      if (element.scrollHeight > parseInt(computed.height))
        style.overflowY = "scroll";
    } else {
      style.overflow = "hidden";
    }

    div.textContent = element.value.substring(0, position);

    if (element.nodeName === "INPUT") {
      div.textContent = div.textContent.replace(/\s/g, " ");
    }

    const span = this.document.createElement("span");
    span.textContent = element.value.substring(position) || ".";
    div.appendChild(span);

    const rect = element.getBoundingClientRect();
    const doc = this.document.documentElement;
    const windowLeft =
      (this.window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    const windowTop =
      (this.window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    let top = 0;
    let left = 0;
    // if (this.menuContainerIsBody) {
    top = rect.top;
    left = rect.left;
    // }

    const coordinates = {
      top:
        top +
        windowTop +
        span.offsetTop +
        parseInt(computed.borderTopWidth) +
        parseInt(computed.fontSize) -
        element.scrollTop,
      left:
        left +
        windowLeft +
        span.offsetLeft +
        parseInt(computed.borderLeftWidth),
    };

    this.document.body.removeChild(div);
    return coordinates;
  }
  private getContentEditableCaretPosition(selectedNodePosition) {
    const markerTextChar = "﻿";
    const markerId = `sel_${new Date().getTime()}_${Math.random()
      .toString()
      .substr(2)}`;
    const sel = this.window.getSelection();
    const prevRange = sel.getRangeAt(0);

    const range = this.document.createRange();
    range.setStart(sel.anchorNode, selectedNodePosition);
    range.setEnd(sel.anchorNode, selectedNodePosition);

    range.collapse(false);

    // Create the marker element containing a single invisible character using DOM methods and insert it
    const markerEl = this.document.createElement("span");
    markerEl.id = markerId;

    markerEl.appendChild(this.document.createTextNode(markerTextChar));
    range.insertNode(markerEl);
    sel.removeAllRanges();
    sel.addRange(prevRange);

    const rect = markerEl.getBoundingClientRect();
    const doc = this.document.documentElement;
    const windowLeft =
      (this.window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    const windowTop =
      (this.window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    let left = 0;
    let top = 0;
    left = rect.left;
    top = rect.top;

    const coordinates = {
      left: left + windowLeft,
      top: top + markerEl.offsetHeight + windowTop,
    };

    markerEl.parentNode.removeChild(markerEl);
    return coordinates;
  }
}
